@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

.totalEntries {
  @include font-size(10px);

  font-family: var(--walsheim);
  font-weight: bold;
  letter-spacing: 1.05px;

  .label {
    text-transform: uppercase;
    color: #AFAFAF;
  }

  .result {
    color: #6C6C6C;
  }
}
