//
//  src\pages\BuyRequestQuestionnaire\components\Form\Form.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

#purchase-status {
  @include margin(0 auto);

  max-width: 1000px;

  #header {
    text-align: center;

    .title {
      @include margin(50px auto);

      max-width: 900px;
      @include font-size(64px);

      color: var(--branding3);
      font-family: var(--walsheim);
      font-weight: bold;

      span {
        color: var(--branding2);
      }
    }
  }

  #purchase-details {
    @include padding(50px);

    @include media-breakpoint-up(md) {
      @include margin(0 auto 75px auto);
    }

    @include media-breakpoint-down(md) {
      @include margin(0 auto 50px auto);
    }

    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .02), 0 9px 10px 1px rgba(0, 0, 0, .04), 0 4px 10px 3px rgba(0, 0, 0, .05);
  }

  .step-name {
    @include font-size(16px);

    color: var(--branding1);
    font-family: var(--walsheim);
    font-weight: bold;
    letter-spacing: 1.33px;
    text-transform: uppercase;
  }

  .seller-name {
    font-weight: bold;
  }

  .sub-text {
    @include font-size(18px);
    color: var(--branding3);
  }
  
  .iniiate-escrow-btn {
    @include padding(15px 40px);
    @include font-size(16px);

    font-weight: bold;
  }

  .status-text {
    @include font-size(24px);

    font-weight: bold;
    color: var(--branding3);
    text-transform: capitalize;
  }

  .status-update-text {
    @include font-size(18px);
  
    color: #8D8D8D;
    font-style: italic;
  }

  .questionAsked {
    @include font-size(28px);
    @include margin(15px 0 0);
    @include padding(10px 0);

    color: var(--branding3);
    font-weight: bold;
    letter-spacing: -.5px;
  }

  .error {
    color: red;
  }
}
