//
// src\styles\base\_global.scss
//

html,
body,
#root,
#root > div {
  height: 100%;
}

html {
  @include font-size(16px);

  body {
    @include font-size(16px);

    font-family: $roboto;
  }

  .container {
    max-width: 1200px;
  }
}
