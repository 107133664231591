//
//  src\pages\BuyRequests\BuyRequests.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../styles/mixins/rfs";

.requestedItem {
  @include padding(25px 15px);
  @include margin(0 0 15px 0);

  background-color: var(--white);
  border: 1px solid #e2e2e2;
}

.requestedItemTitle {
  @include font-size(24px);

  font-weight: bold;
  color: #192661;
}

.addToFav {
  @include font-size(32px);

  position: relative;

  .favourite {
    color: var(--branding2);
    cursor: pointer;
  }

  svg {
    color: #e2e2e2;
    transition: 0.2s color ease-in-out;

    &:hover {
      color: var(--branding2);
      cursor: pointer;
    }
  }
}

.requestedItemDesc {
  @include font-size(16px);
  @include padding(15px 0);

  color: #3b3b3b;
}

.byUser {
  @include font-size(14px);

  font-weight: 500;

  .byLabel {
    @include margin(0 5px 0 0);

    font-weight: bold;
    color: #131313;
  }

  .byUserName {
    color: var(--branding1);

    a,
    a:visited {
      color: var(--branding1);

      &:hover {
        color: var(--branding1-hov);
      }
    }
  }

  .byPole {
    @include margin(0 5px);

    color: #adadad;
  }
}

.submittedWrap {
  @include font-size(16px);
  @include padding(10px 0);

  .submittedResult {
    color: var(--branding2);
  }

  .submittedLabel {
    @include margin(0 10px 0 0);

    color: #ababab;
  }
}

.keywordListing {
  list-style-image: none;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    @include font-size(16px);
    @include padding(8px 10px);
    @include margin(5px);

    display: inline-block;
    background-color: var(--white);
    border: 1px solid #d6d6d6;
    color: #999;
    font-weight: 500;
    text-transform: capitalize;
    transition: 0.2s background-color ease-in-out, 0.2s color ease-in-out;

    &:hover {
      background-color: darken(white, 5%);
      color: var(--branding3);
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.buyRequestBudgetData {
  @include padding(10px 0);
}

.buyRequestBudget {
  @include font-size(18px);

  @include media-breakpoint-up(md) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  font-weight: 500;

  .budgetLabel {
    color: var(--branding3);
  }

  .budgetAmount {
    color: var(--branding1);
  }

  .viewContest {
    @include padding(10px 15px);

    font-family: var(--walsheim);
    font-weight: 600;
  }
}

.error {
  @include font-size(10px);

  color: red;
  text-align: right;
}
