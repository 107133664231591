//
//  src\pages\DomainListing\DomainLIsting.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

@mixin label-style {
  @include font-size(16px);
  @include margin(0 0 15px 0);

  color: #1c1c1c;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: -0.16px;
  font-family: var(--walsheim);
  font-weight: bold;
}

.domListing {
  background-color: #f9f9f9;

  .domListingHeader {
    @include padding(50px 0);

    .domListingTitle {
      @include media-breakpoint-up(lg) {
        @include font-size(52px);

        text-align: left;
      }

      @include media-breakpoint-down(lg) {
        @include font-size(64px);
        @include margin(0 0 25px 0);

        text-align: center;
      }

      color: var(--branding3);
      font-family: var(--walsheim);
      font-weight: bold;
      letter-spacing: -1.49px;
      line-height: 1.12;

      span {
        color: var(--branding2);
      }
    }

    .domListingDescription {
      @include font-size(16px);
      @include padding-top(15px);

      @include media-breakpoint-up(lg) {
        text-align: left;
      }

      @include media-breakpoint-down(lg) {
        text-align: center;
      }

      color: var(--branding3);
      line-height: 1.63;
      font-weight: 400;
    }
  }

  .addBorder {
    select,
    .select {
      min-height: 48px;
      border: 1px solid #ced4da;
      border-radius: 0;
    }

    select {
      @include padding(5px 15px);
    }

    div.select {
      border-bottom: 0 !important;
    }

    input {
      @include padding(5px 15px);

      border: 1px solid #ced4da;
      border-radius: 0;
      margin: 0;
      min-height: 48px;

      &:focus {
        border-color: var(--branding1);
      }
    }
  }

  .domListingSearchOptions {
    @include padding-top(15px);
    @include margin-top(15px);
    @include margin-bottom(15px);

    .domListingSearchLabel {
      @include label-style;
    }
  }

  .domListingSearchKeyWrap {
    button {
      width: 100%;
      max-width: 100%;
      height: 48px;
      position: relative;
    }
  }

  .inputTo {
    display: flex;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    justify-content: center;
    align-items: center;
    color: var(--branding3);
  }

  .domainList {
    min-height: 100px;
    position: relative;
  }

  .domListingSortBy {
    @include padding-top(30px);

    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e8e8e8;

    .domListingSortTotal {
      @include label-style;
    }

    .domListingSortBox {

      .sortBy {
        padding-right: 28px !important; 
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .domListingSortLabel {
        @include label-style;

        max-width: 80px;
      }

      #sortSelect {
        min-width: 175px;
      }
    }
  }

  .error {
    font-size: 12px;
    color: red;
  }
}
