//
//  src\pages\Registration\Registration.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.authBG {
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  height: 100%;
  width: 100%;
  background-color: var(--branding3);
  overflow: auto;
}

.authLogo {
  @include margin(15px);
}

.authWrap {
  margin: 25px auto 25px auto !important;
  max-width: 100%;
  width: 390px;
  text-align: center;
}

.registration {
  .authTitle {
    @include font-size(24px);
    @include margin(15px 0 15px 0);

    font-family: var(--walsheim);
    font-weight: bold;
    letter-spacing: -0.5px;
    color: var(--branding3);
  }

  form {
    @include padding(15px);

    background-color: var(--white);
    border-radius: 0.25rem 0.25rem 0 0;
    min-height: 475px;

    .eula,
    .orLabel {
      @include margin(10px 0);
      @include font-size(12px);

      text-align: center;
      color: #92929d;
    }

    .error {
      color: red;
      font-size: 13px;
      text-align: left;
    }

    .apiError {
      @include padding(10px 15px 5px 15px);
    }
  }

  .formBottom {
    @include font-size(12px);
    @include padding(15px);

    border: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    text-align: center;
    font-weight: bold;
    background-color: #fafafb;
  }
}
