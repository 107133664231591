//
//  src\pages\Dashboard\Dashboard.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.dashWrapper {
  background-color: #f9f9f9;

  .dashInner {
    @include padding(50px 15px);
  }
}
