//
//  src\pages\Dashboard\Notifications\components\Notify\Notify.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../../styles/mixins/rfs';

.notification {
  @include font-size(16px);
  @include margin-bottom(15px);
  @include padding(15px 25px);

  display: flex;
  font-weight: 500;
  color: #131313;
  background-color: var(--white);
  border: 1px solid #e2e2e2;
  transition: 0.2s color ease-in-out, 0.2s background ease-in-out, 0.2s border ease-in-out;

  &:hover {
    border: 1px solid var(--branding1);
    cursor: pointer;
  }

  .notifTitle {
    max-width: 75%;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .timeAgo {
    max-width: 25%;
    width: 100%;
    text-align: right;
  }

  &.read {
    background-color: transparent;
    border: 1px solid #e2e2e2;

    &:hover {
      border-color: #e2e2e2;
    }
  }
}
