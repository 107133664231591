//
// src\styles\mixins\_opacity.scss
//

$opaque-level-1: 0.3;
$opaque-level-2: 0.6;
$opaque-level-3: 0.9;
$opaque-level-4: 0.95;
$opaque-level-5: 1;

$opaque-levels: (
  '1': $opaque-level-1,
  '2': $opaque-level-2,
  '3': $opaque-level-3,
  '4': $opaque-level-4,
  '5': $opaque-level-5,
) !default;

@each $level in $opaque-levels {
  .opacity-#{nth($level, 1)} {
    opacity: #{nth($level, 2)};
  }
}
