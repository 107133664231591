//
// _strAnim.scss
//

@keyframes leftToRight {
  0% {
    width: 0%;
    opacity: 1;
  }

  50% {
    width: 100%;
    opacity: 0;
  }

  100% {
    width: 0%;
    opacity: 0;
  }
}

@mixin overlay($color) {
  position: relative;
  box-sizing: content-box;
  overflow: hidden;

  &::before {
    @if (lightness($color) > 50) {
      background-color: rgba(0, 0, 0, .15);
    }

    @else {
      background-color: rgba(255, 255, 255, .25);
    }

    position: absolute;
    display: block;
    overflow: hidden;
    content: '';
    width: 10%;
    left: 0;
    height: 100%;
    animation-name: leftToRight;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

@mixin strengthAnimation($color) {
  @if (lightness($color) > 50) {
    @include overlay($color);

    background-color: mix(white, $color, 15%);
  }

  @else {
    @include overlay($color);

    background-color: mix(black, $color, 15%);
  }
}
