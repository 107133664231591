//
//  src\pages\Dashboard\Notifications\Notifications.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

.dashWrapper {
  background-color: #f9f9f9;

  .dashInner {
    @include padding(50px 15px);

    .notifItems {
      position: relative;
      min-height: 100px;
    }

    .noNotifications {
      border: 1px solid lightgrey;
      padding: 20px 50px;
      color: #999;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

.titleRegion {
  @include margin-bottom(25px);

  display: flex;
  align-items: center;

  .notifTitle {
    @include font-size(40px);

    color: var(--branding3);
    display: flex;
    font-family: var(--walsheim);
    font-weight: bold;
    line-height: 1.05;
    letter-spacing: -0.67px;
    max-width: 100%;
    width: 100%;
  }

  .markAllRead {
    @include font-size(16px);
    @include padding(10px 20px);

    font-family: var(--roboto);
    font-weight: 500;
    border-radius: 0.15rem;
    margin-left: auto;
  }
}
