//
//  src\components\Subscribe\Subscribe.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.subscribeRegion {
  margin-top: -50px;

  #subscribe {
    @include padding(75px 0 0);

    @include media-breakpoint-up(lg) {
      height: 200px;
    }

    @include media-breakpoint-down(lg) {
      height: 250px;
    }

    @include media-breakpoint-down(md) {
      height: 400px;
    }

    align-items: center;
    background-color: var(--branding2);
    display: flex;
    position: relative;
    z-index: 1;

    .bgImg {
      @include media-breakpoint-up(lg) {
        display: block;
      }

      @include media-breakpoint-down(lg) {
        display: none;
      }

      text-align: center;
      max-width: 100%;
      width: 100%;
      position: absolute;
    }

    .subscribeWrapper {
      position: relative;
      z-index: 1;

      .title {
        @include media-breakpoint-up(md) {
          @include font-size(36px);

          line-height: 1;
          letter-spacing: -0.36px;
        }

        @include media-breakpoint-down(md) {
          @include font-size(32px);

          line-height: 1;
          text-align: center;
          letter-spacing: -0.36px;
        }

        font-family: var(--walsheim);
        font-weight: bold;
        color: var(--branding3);
      }

      .subtitle {
        @include media-breakpoint-up(md) {
          @include margin(25px 0 0);
          @include font-size(24px);
        }

        @include media-breakpoint-down(md) {
          @include font-size(20px);
          @include padding(25px 0);

          max-width: 325px;
          margin: 0 auto;
          text-align: center;
        }

        color: var(--branding3);
      }

      .subInputWrap {
        @include media-breakpoint-up(lg) {
          max-width: 100%;
          width: 100%;
        }

        @include media-breakpoint-down(lg) {
          @include margin(0 auto);

          max-width: 450px;
          width: 100%;
        }

        .subLabel {
          @include font-size(12px);
          @include margin(0 0 15px 0);

          @include media-breakpoint-up(md) {
            text-align: left;
          }

          @include media-breakpoint-down(md) {
            text-align: center;
          }

          font-weight: bold;
          color: var(--branding3);
          text-transform: uppercase;
          letter-spacing: 3px;
        }

        .subInput {
          @include font-size(1.5rem);

          border-radius: 0;
          border:1px solid var(--white);
          box-sizing: border-box;
          height: 70px;
          max-width: 100%;
          width: 100%;

          &:focus {
            outline: 0;
            box-shadow: none;
          }

          &::placeholder {
            @include font-size(16px);

            font-style: italic;
            color: var(--branding3);
          }
        }

        .subSubmit {
          align-items: center;
          display: flex;
          justify-content: center;
          position: absolute;
          right: 11px;
          top: 13px;
          width: 45px;
          height: 45px;
          background-color: var(--branding3);
          border: 1px solid var(--branding3);
          z-index: 99;

          &:hover {
            background-color: var(--branding3-hov);
            border-color: var(--branding3-hov);
          }
        }

        .error {
          color: red;
          margin-top: 10px;
        }
      }
    }
  }

  .subTopWave {
    margin-bottom: -20px;
    position: relative;
    z-index: 1;
  }

  .subBotWave {
    margin-top: -20px;
  }
}
