//
//  src\pages\Dashboard\AccountPreferences\BillingDetails\BillingDetails.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.billingDetails {
  @include padding(20px 10px);
  @include margin-bottom(20px);

  background-color: var(--white);

  .error {
    font-size: 14px;
    color: red;
  }

  .apiError {
    padding-top: 10px;
  }
}

.prefLabel {
  @include font-size(24px);
  @include margin(25px 0 10px 0);

  font-weight: bold;
  line-height: 2.13;
  letter-spacing: normal;
  color: var(--branding3);
}

.paymentMethod {
  @include media-breakpoint-up(lg) {
    display: flex;
  }

  .paymentItem {
    @include padding(15px);

    @include media-breakpoint-up(lg) {
      @include margin(0 30px 0 0);

      max-width: 33%;

      &:last-child {
        margin: 0;
      }
    }

    @include media-breakpoint-down(lg) {
      @include margin(0 auto 25px auto);

      max-width: 250px;
    }

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 175px;
    border: 2px solid #e6e6e6;
    border-radius: 0.25rem;
    color: var(--branding3);
    position: relative;
    transition: 0.25s background-color ease-in-out, 0.25s border ease-in-out;
    width: 100%;

    .logo {
      position: relative;
    }

    .title {
      @include font-size(18px);
      @include margin(15px 0 0 0);

      color: var(--branding3);
      position: relative;
    }

    &.active,
    &:hover {
      background-color: #f5faff;
      border-color: #0078ff;
      cursor: pointer;
    }

    &::before {
      @include font-size(22px);

      align-items: center;
      content: '\2714';
      color: #0078ff;
      display: flex;
      justify-content: center;
      position: absolute;
      right: -20px;
      top: -20px;
      background-color: #f5faff;
      border: 1px solid #0078ff;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      transform: scale(0);
      transition: 0.25s transform ease-in-out;
    }

    &.active {
      &::before {
        transform: scale(1);
      }
    }
  }
}
