//
//  src\pages\DomainListing\DomainLIsting.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

@mixin label-style {
  @include font-size(16px);
  @include margin(0 0 15px 0);

  color: #1c1c1c;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: -0.16px;
  font-family: var(--walsheim);
  font-weight: bold;
}

.domainListing {
  .error {
    font-size: 12px;
    color: red;
  }

  .noEntries {
    border: 1px solid lightgrey;
    padding: 20px 50px;
    color: #999;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    margin: 45px 15px;
  }
}
