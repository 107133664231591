//
//  src\pages\Home\components\PopularContests\PopularContests.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

#popularWrap {
  #topWave {
    svg {
      @include margin(0 0 -5px 0);

      max-width: 100%;
      width: 100%;
    }
  }

  #popularContests {
    @include padding(0 0 100px 0);

    position: relative;
    background-color: var(--branding3);
    min-height: 450px;

    .titleRegion {
      @include padding(0 0 35px);

      text-align: center;
      color: var(--white);

      .title {
        @include font-size(30px);

        font-family: var(--walsheim);
        font-weight: bold;
        letter-spacing: -0.3px;
      }

      .subtitle {
        @include font-size(16px);
      }
    }
  }
}
