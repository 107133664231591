//
//  src\pages\Dashboard\Notifications\Notifications.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

.dashWrapper {
  background-color: #f9f9f9;

  .dashInner {
    @include padding(50px 15px);

    .sectionTitle {
      @include font-size(40px);
      @include margin-bottom(25px);

      font-family: var(--walsheim);
      font-weight: bold;
      line-height: 1.05;
      letter-spacing: -0.67px;
      color: var(--branding3);
    }

    .noTransactions {
      border: 1px solid lightgrey;
      padding: 20px 50px;
      color: #999;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .searchIcon {
    position: absolute;
    top: 2px;
    right: 15px;
    background-color: transparent;
    border: transparent;
    color: #1C1C1C;
  }
}
