//
//  src\pages\Dashboard\Messages\MessageInner\MessageInner.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

.dashWrapper {
  background-color: #f9f9f9;

  .dashInner {
    @include padding(50px 15px);
  }
}

.innerMessageContainer {
  background-color: var(--white);
  border: 1px solid #e2e2e2;

  .innerMessageWrapper {
    @include padding(25px);

    .innerMessageHeader {
      @include margin-bottom(50px);

      display: flex;
      align-items: center;
      padding-right: 0;

      .backToAll {
        text-align: left;
      }

      .reportUser {
        @include font-size(12px);

        font-weight: 500;
        margin-left: auto;
        border-radius: 0;
        border: 1px solid #e7e7e7;
        color: #ababab;

        &:hover {
          background-color: lighten(#e7e7e7, 5%);
          color: var(--branding3);
          font-weight: 500;
        }

        &:visited {
          @extend .reportUser;
        }
      }
    }

    h4 {
      text-align: center;
      margin-top: -20px;
      margin-bottom: 20px;
    }

    .writeMessage {
      @include margin-top(25px);

      position: relative;

      .textArea {
        position: relative;

        .messageAttach {
          @include font-size(24px);

          position: absolute;
          right: 25px;
          top: 8px;
          color: var(--branding3);
          transition: 0.6 transform ease-in-out;
          transform: scale(1);

          &:hover {
            color: var(--branding3-hov);
            transform: scale(0.9);
          }
        }
      }

      .sendMessage {
        @include padding(10px 25px);
        @include font-size(16px);

        font-weight: 500;
        border-radius: 0;
      }
    }
  }
}
