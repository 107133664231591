//
//  src\pages\Home\components\PopularContests\PopularContest\PopularContest.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../../styles/mixins/rfs';

.buyRequestBlock {
  .buyRequestBlockWrap {
    @include padding(25px);

    @include media-breakpoint-down(md) {
      @include margin(0 0 50px 0);
    }

    background-color: #192661;

    .title {
      @include font-size(24px);

      font-weight: bold;
      color: var(--white);
    }

    .description {
      @include font-size(16px);
      @include padding(15px 0);

      color: var(--white);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .info {
      @include font-size(16px);
      @include padding(0 0 15px 0);

      color: var(--white);

      svg {
        color: var(--branding2);
      }

      .integer {
        color: var(--branding2);
        font-weight: normal;
      }

      .timeleft {
        color: var(--branding2);
      }

      .submitted {
        @include margin(0 10px 0 0);
      }

      .budget {
        @include padding(10px 0 0);

        font-weight: bold;
      }
    }

    .enterBuyRequest {
      max-width: 100%;
      width: 100%;
      box-sizing: border-box;
    }
  }
}
