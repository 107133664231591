//
//  src\pages\Home\components\PopularNames\PopularNames.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

#popularNames {
  @include padding(75px 0);

  position: relative;
  background-color: var(--branding3);
  min-height: 600px;

  .titleRegion {
    @include padding(0 0 35px);

    text-align: center;
    color: var(--white);

    .title {
      @include font-size(30px);

      font-family: var(--walsheim);
      font-weight: bold;
      letter-spacing: -0.3px;
    }

    .subtitle {
      @include font-size(16px);
      @include padding(15px 0);
    }
  }

  .popularDomains {
    position: relative;
  }
}
