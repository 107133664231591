@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.termsContainer {
  color: var(--branding3);

  .pageHeader {
    @include font-size(24px);
    font-weight: bolder;
    text-align: center;
  }

  .section {
    @include margin-top(2rem);

    .sectionHeader {
      font-size: 18px;
      font-weight: bold;
    }

    p {
      font-size: 1rem;
      line-height: 1.8rem;
    }
  }
}
