@import '../../../styles/mixins/rfs';

.searchBuyRequest {
  position: relative;
  @include margin(15px 0);

  .buyRequestSubmit {
    position: absolute;
    top: 2px;
    right: 15px;
    background-color: transparent;
    border: transparent;
    color: #1C1C1C;
  }
}
