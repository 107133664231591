//
//  src\pages\DomainListing\DomainInner\DomainInner.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

@mixin bgWrap {
  @include padding(15px);

  background-color: var(--white);
  border: 1px solid #e2e2e2;
}

@mixin labelStyle {
  @include font-size(18px);

  color: #1c1c1c;
  font-family: var(--walsheim);
  font-weight: bold;
  letter-spacing: -0.18px;
}

@mixin keywordStyle {
  @include margin(0 0 50px 0);

  list-style-image: none;
  list-style-type: none;
  padding: 0;

  li {
    @include margin-bottom(10px);

    display: inline-block;
    min-height: 37px;

    a {
      @include font-size(16px);
      @include padding(8px 10px);
      @include margin(5px);

      background-color: var(--white);
      border: 1px solid #d6d6d6;
      color: #999;
      font-weight: 500;
      transition: 0.2s background-color ease-in-out, 0.2s color ease-in-out;

      &:hover {
        background-color: darken(white, 5%);
        color: var(--branding3);
      }
    }

    &:first-child {
      a {
        margin-left: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.modal90 {
  margin: 30px auto !important;
  width: 90% !important;
  max-width: none !important;
}

.titleRegion {
  @include margin(25px 0);

  .subTitle {
    @include font-size(14px);
    @include margin-bottom(-10px);

    color: var(--branding1);
    text-transform: uppercase;
    letter-spacing: 1.17px;
    font-weight: 900;
  }

  .title {
    @include font-size(32px);

    color: var(--branding3);
    font-weight: bold;
    letter-spacing: -0.91px;
  }
}

.domainInner {
  @include padding(25px 0 100px 0);

  background-color: #f9f9f9;
  position: relative;
  min-height: 200px;
}

.mainImage {
  @include margin-bottom(15px);
}

.otherImages {
  list-style-type: none;
  list-style-image: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.shareRegion {
  @include margin(25px 0);

  ul {
    list-style-type: none;
    list-style-image: none;
    margin: 0;
    padding: 0;

    li {
      @include margin(0 15px 0 0);

      color: #b4b4b4;
      display: inline-block;
      font-weight: bold;
      padding: 0;
      vertical-align: middle;

      a {
        color: var(--branding3);
        display: block;
        font-weight: normal;
        min-height: 30px;

        &:hover {
          color: var(--branding3-hov);
        }
      }

      &.shareButton {
        @include font-size(16px);

        svg {
          @include font-size(24px);
          @include margin(0 5px 0 0);
        }
      }
    }
  }
}

.keywords {
  @include keywordStyle;
}

.categoriesWrap {
  margin-top: -40px;
}

.categories {
  @include keywordStyle;
  margin-bottom: 1rem !important;
}

.contentWrap {
  @include bgWrap;
  @include margin-bottom(25px);

  .contentTitle {
    @include font-size(18px);
    @include margin(15px 0);

    color: var(--branding3);
    font-weight: bold;
  }

  .contentDescription {
    @include font-size(16px);

    color: var(--branding3);
    line-height: 1.63;
  }

  .keyCatLabel {
    @include font-size(18px);
    @include margin(15px 0);

    color: var(--branding3);
    font-weight: bold;
  }
}

.sidebarBlock {
  @include bgWrap;
  @include margin-bottom(25px);
}

.buyItNow {
  .buyItNowHeader {
    @include padding-top(15px);
    @include padding-bottom(15px);
    @include margin-top(-15px);
    @include margin-bottom(15px);

    align-items: center;
    background-color: #f2f2f2;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;

    .buyItNowAmount {
      @include font-size(30px);

      color: var(--branding3);
      letter-spacing: -0.83px;
      font-weight: 900;
    }

    .buyItFavorite {
      @include font-size(32px);

      color: #e2e2e2;
      transition: 0.2s color ease-in-out;
      cursor: pointer;

      &:hover {
        color: var(--branding2);
      }

      .heartFav {
        color: var(--branding2);
        cursor: pointer;
      }
    }

    .disabledFav {
      pointer-events: none;
    }
  }

  .buyItNowTitle {
    @include labelStyle;
  }

  .whatYouGet {
    @include margin(15px 0);

    list-style-type: none;
    list-style-image: none;
    padding: 0;

    li {
      @include font-size(16px);
      @include padding(0 0 5px 0);

      color: var(--branding3);
      vertical-align: middle;

      &::before {
        @include font-size(20px);

        content: "\2713";
        color: var(--branding2);
        font-weight: bold;
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  .buyItNowButton {
    @include padding(10px);

    box-sizing: border-box;
    font-weight: bold;
    max-width: 100%;
    width: 100%;
  }
}

.selectPayment {
  .selectPaymentTitle {
    @include labelStyle;
  }

  .selectPaymentDesc {
    @include font-size(16px);
    @include padding(5px 0);

    color: var(--branding3);
    line-height: 1.25;
  }

  .selectPaymentAmount {
    list-style-type: none;
    list-style-image: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0;
      padding: 0;
      vertical-align: center;

      .selectPaymentItem {
        @include font-size(16px);
        @include padding(10px 15px);
        @include margin(5px);

        background-color: transparent;
        border: 1px solid var(--branding1);
        color: var(--branding1);
        display: inline-block;
        font-weight: bold;
        min-width: 50px;
        text-align: center;
        vertical-align: center;
        transition: 0.2s background-color ease-in-out, 0.2s color ease-in-out;

        &:hover {
          background-color: var(--branding1);
          color: var(--white);
        }
      }

      &:first-child {
        .selectPaymentItem {
          margin-left: 0;
        }
      }

      &:last-child {
        .selectPaymentItem {
          margin-right: 0;
        }
      }
    }
  }

  .selectPaymentPrice {
    @include font-size(16px);
    @include margin(15px 0);

    color: #1c1c1c;

    span {
      @include font-size(24px);

      font-weight: bold;
    }
  }

  .paymentSubmit {
    @include padding(10px);

    box-sizing: border-box;
    font-weight: bold;
    max-width: 100%;
    width: 100%;
  }
}

.inquireForm {
  .inquireTitle {
    @include labelStyle;
  }

  .inquireSubmit {
    @include padding(10px);

    box-sizing: border-box;
    font-weight: bold;
    max-width: 100%;
    width: 100%;
  }

  .addBorder {
    select,
    .select {
      min-height: 48px;
      border: 1px solid #ced4da;
      border-radius: 0;
    }

    select {
      @include padding(5px 15px);
    }

    div.select {
      border-bottom: 0 !important;
    }

    input {
      @include padding(5px 15px);

      border: 1px solid #ced4da;
      border-radius: 0;
      margin: 0;
      min-height: 48px;

      &:focus {
        border-color: var(--branding1);
      }
    }
  }

  .error {
    color: red;
    font-size: 13px;
    text-align: left;
  }
}
