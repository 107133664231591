@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';


.rating-filters {
  @include margin(15px 0);

  .nav-item {
    .rank-pill {
      @include font-size(16px);
      @include padding(5px 5px);

      @include media-breakpoint-up(xl) {
        @include margin(0 10px 0 0);
      }

      background-color: var(--white);
      border: 1px solid #d6d6d6;
      border-radius: 0;
      color: #999;
      font-weight: 500;

      &:hover,
      &.active {
        border: 1px solid var(--branding1);
        color: var(--branding1);
      }
    }

    .selected {
      border: 1px solid var(--branding1);
      color: var(--branding1);
    }

    &:last-child {
      .rank-pill {
        @include media-breakpoint-up(xl) {
          margin: 0;
        }
      }
    }
  }
}
