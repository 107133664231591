//
//  src\pages\Dashboard\Messages\MessageInner\components\MessageReplyInquirer\MessageReplyInquirer.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../../../styles/mixins/rfs';

.replyMessage {
  @include margin-bottom(25px);

  .messageAvatar {
    text-align: right;

    img {
      border-radius: 50%;
      border-color: 1px solid #efefef;
      height: 55px;
      width: 55px;
    }
  }

  .messageAgo {
    @include padding(2px 0);
    @include font-size(12px);

    font-style: italic;
    color: #aaa;
  }

  .messageFrom {
    @include font-size(12px);

    font-weight: bold;
    color: var(--branding1);
  }

  .messageReply {
    @include padding(15px);

    border-style: solid;
    border-width: 1px;
    border-color: #dedede;
  }
}
