//
//  src\pages\Pricing\components\HowItWorks\HowItWorks.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../../styles/mixins/rfs';

.hiwRegion {
  @include margin(-75px 0 0 0);
}

.hiwBanner {
  @include padding(50px 0 80px 0);
  @include margin(-1px 0 25px 0);

  background-color: var(--branding3);

  .hiwRegionTitle {
    @include font-size(30px);
    @include padding(30px 0 60px 0);

    color: var(--white);
    font-family: var(--walsheim);
    font-weight: bold;
    letter-spacing: -.3px;
    text-align: center;
  }
}

.hiwBlock {
  @include media-breakpoint-down(md) {
    margin-bottom: 50px;
  }

  align-items: center;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
  text-align: center;

  .hiwIcon {
    min-height: 125px;

    .hiwBlob {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .hiwTitle {
    @include padding(15px 0);

    @include media-breakpoint-up(lg) {
      @include font-size(24px);

      line-height: 28px;
    }

    @include media-breakpoint-up(lg) {
      @include font-size(20px);

      line-height: 24px;
    }

    font-weight: bold;
  }

  .hiwDescription {
    @include media-breakpoint-up(lg) {
      @include font-size(16px);

      line-height: 24px;
      margin: 0 auto;
      max-width: 95%;
    }

    @include media-breakpoint-down(lg) {
      @include font-size(15px);

      line-height: 20px;
      margin: 0 auto;
      max-width: 400px;
    }
  }

  .TopWave {
    margin-bottom: -5px;
  }
}
