//
//  src\pages\DomainListing\DomainInner\components\Gallery\Gallery.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../../styles/mixins/rfs';

.modal90 {
  margin: 30px auto !important;
  width: 90% !important;
  max-width: none !important;
}

.otherImg {
  @include margin(0 5px 5px 0);

  @include media-breakpoint-up(xl) {
    width: calc(20% - 5px);

    &:nth-child(5n+5) {
      margin-right: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    width: calc(25% - 5px);

    &:nth-child(4n+4) {
      margin-right: 0;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    border: 1px solid transparent;
    border-radius: 0.2rem;
    transition: 0.2s border ease-in-out;
  }

  &:hover {
    cursor: pointer;

    img {
      border: 1px solid var(--branding1);
    }
  }
}
