//
// src\pages\Registration\components\PasswordStrength.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/strAnim';
@import '../../../../styles/mixins/rfs';

.passwordStrength {
  .strBlock {
    .strIndicator {
      background-color: #e2e2ea;
      height: 4px;
      border-right: 1px solid #fff;
      transition: 0.2s background-color ease-in-out;
    }

    &.strBlockFirst {
      .strIndicator {
        border-radius: 4px 0 0 4px;
      }
    }

    &.strBlockLast {
      .strIndicator {
        border-radius: 0 4px 4px 0;
        border-right: transparent;
      }
    }
  }

  .strLabel {
    @include font-size(12px);

    color: #92929d;
    text-align: right;
    letter-spacing: 0.025rem;
  }

  &[data-strength='strong'] {
    .strBlockFirst,
    .strBlockSecond,
    .strBlockThird,
    .strBlockFourth,
    .strBlockLast {
      .strIndicator {
        @include strengthAnimation(#54febd);
      }
    }

    .strLabel {
      .strLabelResult {
        &::after {
          content: 'Strong';
          color: var(--branding2);
        }
      }
    }
  }

  &[data-strength='good'] {
    .strBlockFirst,
    .strBlockSecond,
    .strBlockThird,
    .strBlockFourth {
      .strIndicator {
        @include strengthAnimation(#26de81);
      }
    }

    .strLabel {
      .strLabelResult {
        &::after {
          content: 'Good';
          color: #26de81;
        }
      }
    }
  }

  &[data-strength='ok'] {
    .strBlockFirst,
    .strBlockSecond,
    .strBlockThird {
      .strIndicator {
        @include strengthAnimation(#fed330);
      }
    }

    .strLabel {
      .strLabelResult {
        &::after {
          content: 'Ok';
          color: #fed330;
        }
      }
    }
  }

  &[data-strength='weak'] {
    .strBlockFirst,
    .strBlockSecond {
      .strIndicator {
        @include strengthAnimation(#fc5c65);
      }
    }

    .strLabel {
      .strLabelResult {
        &::after {
          content: 'Weak';
          color: #fc5c65;
        }
      }
    }
  }

  &[data-strength='none'] {
    .strLabel {
      .strLabelResult {
        &::after {
          content: 'None';
        }
      }
    }
  }
}
