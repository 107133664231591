//

button {
  &.bm-btn {
    @include media-breakpoint-down(lg) {
      @include font-size(16px);
    }

    border-radius: 0.25em;
    font-family: $roboto;
    font-weight: bold;

    &.lg {
      @include font-size(18px);

      @include media-breakpoint-up(md) {
        padding: 20px 35px;
      }

      @include media-breakpoint-down(md) {
        padding: 15px 30px;
      }
    }

    &.md {
      @include font-size(16px);

      @include media-breakpoint-up(md) {
        padding: 15px 25px;
      }

      @include media-breakpoint-down(md) {
        padding: 10px 20px;
      }
    }

    &.sm {
      @include font-size(14px);

      @include media-breakpoint-up(md) {
        padding: 10px 15px;
      }

      @include media-breakpoint-down(md) {
        padding: 5px 10px;
      }
    }

    &.btn-outline-transparent {
      color: $branding1;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background-color: $branding1;
        border-color: $branding1;
        color: $white;
      }
    }

    &.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    &.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    &.show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: none;
      outline: none;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active:focus,
    &.btn-primary:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
      outline: none;
    }
  }
}
