//
//  src\general\FAQ\FAQ.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.qaWrapper {
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #e6e6e6;
  border-left: 0;

  .qaQuestion {
    background-color: transparent;
    border: 0;

    button {
      @include media-breakpoint-up(lg) {
        @include font-size(24px);
      }

      @include media-breakpoint-down(lg) {
        @include font-size(20px);
      }

      padding: 10px 0 10px 25px;
      color: var(--branding3);
      text-decoration: none;
      position: relative;
      margin: 0;
      font-weight: bold;

      &::before {
        position: absolute;
        content: '+';
        display: block;
        left: 0;
        top: 10px;
        color: var(--branding1);
        margin-right: 0;
      }
    }
  }

  .qaAnswer {
    @include padding(15px);
  }

  &.qaCard {
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #e6e6e6 !important;
    border-left: 0;
  }

  &.card,
  &.qaCard {
    background-color: transparent;
  }
}
