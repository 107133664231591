//
//  src\pages\Dashboard\Messages\components\MessageItem\MessageItem.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../../styles/mixins/rfs';

.messageItem {
  @include margin-bottom(15px);
  @include padding-bottom(15px);

  border-bottom: 1px solid #d5d5d5;

  .messageAvatar {
    img {
      border-radius: 50%;
      border-color: 1px solid #efefef;
      height: 55px;
      width: 55px;
    }
  }

  .messageTitle {
    @include font-size(14px);

    font-weight: 500;

    .messageTitleWrap {
      max-width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      color: #131313;

      &:hover,
      &:focus,
      &:active {
        color: lighten(#131313, 50%);
      }

      &:visited {
        @extend a;
      }
    }
  }

  .messageAgo {
    @include padding(2px 0);
    @include font-size(12px);

    font-style: italic;
    color: #aaa;
  }

  .messageFrom {
    @include font-size(12px);

    font-weight: bold;
    color: var(--branding1);

    // Reserved: If links are required to the author.
    // a {
    //   color: var(--branding1);

    //   &:hover,
    //   &:focus,
    //   &:active {
    //     color: var(--branding1-hov);
    //   }

    //   &:visited {
    //     @extend a;
    //   }
    // }
  }
}
