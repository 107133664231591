//
//  src\pages\Home\components\WhyUseBrandmo\WhyUseBrandmo.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

#whyUseBrandmo {
  @include padding(75px 0);

  position: relative;

  .title {
    @include font-size(30px);

    font-family: var(--walsheim);
    font-weight: bold;
    letter-spacing: -0.3px;
    text-align: center;
    color: #111b47;
  }

  .whyBlock {
    .whyBlockWrap {
      @include padding(0 15px);

      text-align: center;

      .whyBlockIcon {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        min-height: 175px;

        .blockBlob {
          position: absolute;
        }
      }

      .whyBlockTitle {
        @include font-size(24px);
        @include padding(15px 0);

        font-weight: bold;
        text-align: center;
        color: #111b47;
      }

      .whyBlockDescription {
        @include font-size(15.5px);

        line-height: 22px;
        text-align: center;
        color: #111b47;
      }
    }
  }
}
