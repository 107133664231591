// src\styles\components\_anchor.scss

html {
  body {
    a {
      color: $branding1;
      transition: 0.2s color ease-in-out;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: darken($branding1, 5%);
      }
    }
  }
}
