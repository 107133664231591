//
//  src\general\CustomCheckbox\CustomCheckbox.module.scss
//

@import '../../styles/mixins/rfs';

.bmCheckbox {
  min-height: 40px;

  label {
    color: var(--branding3);
    cursor: pointer;
    display: inline-block;
    height: 1.5em;
    padding: 0 0 0 2em;
    position: relative;

    &::before {
      border: 2px solid #e6e6e6;
      border-radius: .25em;
      display: block;
      height: 1.5em;
      left: 0;
      position: absolute;
      top: 0;
      content: '';
      transition: .25s background-color ease-in-out,
                  .25s color ease-in-out,
                  .25s border-color ease-in-out;
      width: 1.5em;
    }

    &::after {
      display: block;
      height: 1.5em;
      left: 0;
      position: absolute;
      top: 0;
      transition: .25s background-color ease-in-out,
                  .25s color ease-in-out,
                  .25s border-color ease-in-out;
      width: 1.5em;
    }
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    z-index: -1;

    + {
      label {
        &::after {
          align-items: center;
          content: "\2713";
          color: var(--white);
          display: flex;
          justify-content: center;
          text-align: center;
          transform: scale(0);
          transition: .25s transform ease-in-out;
        }
      }
    }

    &:checked {
      + {
        label {
          &::before {
            background: var(--branding1);
            border-color: var(--branding1);
          }

          &::after {
            transform: scale(1);
          }
        }
      }
    }
  }

  .bmCheckInput {
    padding: 0;
  }
}

.bmCheckOther {
  position: relative;

  input {
    @include padding(0 5px);

    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    color: var(--branding3);
    position: relative;

    &::placeholder {
      color: var(--branding3);
    }

    &:active,
    &:focus {
      border-color: var(--branding1);
      box-shadow: none;
      outline: 0;
    }
  }
}
