//
//  src\pages\BuyRequestQuestionnaire\BuyRequestQuestionnaire.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

#questionnaire {
  @include margin(0 auto);

  max-width: 1000px;
  min-height: 200px;
  position: relative;

  #header {
    text-align: center;

    .title {
      @include font-size(64px);

      color: var(--branding3);
      font-family: var(--walsheim);
      font-weight: bold;

      span {
        color: var(--branding2);
      }
    }

    .description {
      @include font-size(16px);
      @include margin(15px auto 0);

      max-width: 800px;
      color: var(--branding3);
    }
  }

  .buy-request-success {
    position: absolute;
    width: inherit;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
