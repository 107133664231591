//
//  src\components\RecentlySold\RecentlySold.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

#recentlySold {
  @include padding(50px 0 150px);

  position: relative;
  background-color: var(--branding3);

  .titleRegion {
    @include padding(0 0 35px);

    text-align: center;
    color: var(--white);

    .title {
      @include font-size(30px);

      font-family: var(--walsheim);
      font-weight: bold;
      letter-spacing: -0.3px;
    }
  }
}
