//
//  src\pages\BuyRequests\BuyRequests.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.buyRequestSearch {
  @include padding(0 0 100px 0);

  background-color: #f9f9f9;
}

.pageTitleRegion {
  text-align: center;

  .pageTitle {
    @include padding(50px 0);
    @include font-size(64px);

    color: var(--branding3);
    font-family: var(--walsheim);
    font-weight: bold;
    line-height: 0.91;
    letter-spacing: -1.83px;
  }

  .pageDesc {
    @include font-size(16px);

    color: var(--branding3);
    line-height: 1.63;
    margin: 0 auto;
    max-width: 960px;
  }
}

.pageContentRegion {
  @include margin(50px auto);

  .buyRequestSearchList {
    position: relative;
    min-height: 200px;
  }
}

.addBorder {
  select,
  .select {
    min-height: 48px;
    border: 1px solid #ced4da;
    border-radius: 0;
  }

  select {
    @include padding(5px 15px);
  }

  div.select {
    border-bottom: 0 !important;
  }

  input {
    @include padding(5px 15px);

    border: 1px solid #ced4da;
    border-radius: 0;
    margin: 0;
    min-height: 48px;

    &:focus {
      border-color: var(--branding1);
    }
  }
}

.searchLabel {
  @include font-size(16px);

  @include media-breakpoint-up(md) {
    @include margin(0 0 15px 0);
  }

  @include media-breakpoint-down(md) {
    @include margin(50px 0 0 0);
  }

  font-family: var(--walsheim);
  font-weight: bold;
  letter-spacing: -0.16px;
  color: #1c1c1c;
}

.sortByWrap {
  @include media-breakpoint-up(md) {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .searchLabel {
      @include margin(0 10px 15px 0);
    }
  }

  @include media-breakpoint-down(md) {
    justify-content: flex-start;
  }

  select {
    margin: 0 !important;
    min-width: 285px;
  }
}
