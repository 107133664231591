//
//  src\pages\BuyRequests\components\KeywordFilter\KeywordFilter.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

.keywordBar {
  @include margin(25px 0);

  height: 1px;
  width: 100%;
  background-color: #e8e8e8;
}

.searchLabel {
  @include font-size(16px);
  @include margin(0 0 15px);

  font-family: var(--walsheim);
  font-weight: bold;
  letter-spacing: -0.16px;
  color: #1c1c1c;
}

.addBorder {
  select,
  .select {
    min-height: 48px;
    border: 1px solid #ced4da;
    border-radius: 0;
  }

  select {
    @include padding(5px 15px);
  }

  div.select {
    border-bottom: 0 !important;
  }

  input {
    @include padding(5px 15px);

    border: 1px solid #ced4da;
    border-radius: 0;
    margin: 0;
    min-height: 48px;

    &:focus {
      border-color: var(--branding1);
    }
  }
}

.filterInputItem {
  @include margin(0 0 25px 0);
}

.applyFilter {
  .applyFilterButton {
    @include padding(10px 15px);

    border-radius: 0 0 0.2rem 0.2rem;
    width: 100%;
  }
}

.keywordFilterWrap {
  .keywordFilter {
    @include padding(25px 15px 15px 15px);

    background-color: var(--white);
    border-left: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
  }
}
