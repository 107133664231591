//
//  src\pages\BuyRequestQuestionnaire\components\Form\Form.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

#checkout {
  @include margin(0 auto);

  max-width: 870px;
  min-height: 200px;
  position: relative;

  .domain-name-text {
    color: var(--branding1);
  }

  #header {
    text-align: center;

    .title {
      @include font-size(64px);
      @include margin(50px auto);

      max-width: 900px;
      color: var(--branding3);
      font-family: var(--walsheim);
      font-weight: bold;

      span {
        color: var(--branding2);
      }
    }
  }

  .error {
    font-size: 14px;
    color: red;
    margin-top: 10px;
  }

  #checkoutForm {
    @include padding(50px);

    @include media-breakpoint-up(md) {
      @include margin(0 auto 75px auto);
    }

    @include media-breakpoint-down(md) {
      @include margin(0 auto 50px auto);
    }

    box-shadow: 0 25px 80px -30px rgba(0, 0, 0, 0.25);
  }

  .step-name {
    @include font-size(16px);

    color: var(--branding1);
    font-family: var(--walsheim);
    font-weight: bold;
    letter-spacing: 1.33px;
    text-transform: uppercase;
  }

  .includes {
    @include font-size(16px);

    font-weight: bold;
    color: var(--branding3);
  }

  .checkoutDomainTitle,
  .checkoutDomainPrice {
    @include font-size(28px);

    color: var(--branding3);
    font-weight: bold;
    letter-spacing: -0.5px;
  }

  .domain-purchase-success {
    position: absolute;
    width: inherit;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
