//
// src\pages\Contact\Contact.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

$page-container: 870px;

.contactPage {
  .headerWrap {
    @include margin(50px auto 50px auto);

    max-width: $page-container;

    .headerTitle {
      @include font-size(64px);

      font-family: var(--walsheim);
      font-weight: bold;
      line-height: 0.91;
      letter-spacing: -1.83px;
      text-align: center;
      color: #111b47;
    }
  }

  .contactForm {
    @include margin-top(80px);
    @include margin-bottom(80px);
    @include padding(40px 40px 60px 40px);

    max-width: $page-container;
    margin: 0 auto;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.02), 0 9px 10px 1px rgba(0, 0, 0, 0.04), 0 4px 10px 3px rgba(0, 0, 0, 0.05);

    .formLabel {
      @include font-size(24px);

      font-family: var(--roboto);
      font-weight: bold;
      line-height: 1.42;
      color: var(--branding3);
    }

    .inputCol {
      @include margin(15px 0);

      .addInput,
      .addTextArea,
      .addSelect {
        > div {
          margin: 0 !important;
        }
      }

      .addSelect {
        select {
          &:focus {
            outline: 0;
            box-shadow: none;
          }
        }
      }

      .addTextArea {
        textarea {
          @include margin-top(25px);

          min-height: 190px;
        }
      }
    }

    .formSubmit {
      button {
        max-width: 100%;
        width: 100%;
      }
    }

    .error {
      margin-top: 10px;
      color: red;
    }
  }

  .success {
    @include margin-top(80px);
    @include padding(40px 40px 60px 40px);

    max-width: $page-container;
    margin: 0 auto;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.02), 0 9px 10px 1px rgba(0, 0, 0, 0.04), 0 4px 10px 3px rgba(0, 0, 0, 0.05);
    min-height: 100px;
    color: green;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
