// Color Schemes
$black: #000000;
$white: #ffffff;
$branding1: #0078ff;
$branding2: #54febd;
$branding3: #111b47;

// Fonts
$roboto: 'Roboto', sans-serif;
$walsheim: 'GT Walsheim', sans-serif;

// SCSS to CSS
@include css-vars((
  // Color Scheme
  --black: $black,
  --white: $white,
  --branding1: $branding1,
  --branding1-hov: darken($branding1, 5%),
  --branding2: $branding2,
  --branding2-hov: darken($branding2, 20%),
  --branding3: $branding3,
  --branding3-hov: lighten($branding3, 15%),
  // Typography
  --roboto: $roboto,
  --walsheim: $walsheim
));
