//
//  src\pages\Dashboard\AccountPreferences\ChangeEmail\ChangeEmail.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

.changeEmail {
  @include padding(60px 40px);
  @include margin-bottom(20px);

  border: 1px solid lightgray;
  background-color: var(--white);

  .error {
    font-size: 14px;
    color: red;
  }

  .apiError {
    padding-top: 10px;
  }

  .updatedSuccessfully {
    font-size: 14px;
    color: green;
    padding-top: 10px;
    font-weight: bold;
  }
}

.prefLabel {
  @include font-size(16px);
  @include margin(25px 0 10px 0);

  font-weight: bold;
  line-height: 2.13;
  letter-spacing: normal;
  color: var(--branding3);
}
