@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700,900&display=swap');

@font-face {
  font-family: 'GT Walsheim';
  src: url('../../assets/fonts/GTWalsheim-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('../../assets/fonts/GTWalsheim.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('../../assets/fonts/GTWalsheim-UltraBold.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('../../assets/fonts/GTWalsheim-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
