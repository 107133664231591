//
//  src\pages\Pricing\components\Table\Table.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

.pricing-table {
  .table {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: 100%;

    tr {
      th {
        @include media-breakpoint-up(lg) {
          @include padding(30px 15px);
          @include font-size(24px);

          border: 1px solid #e6e6e6;
          text-align: center;
        }
      }

      td {
        @include media-breakpoint-up(lg) {
          @include font-size(20px);
          @include padding(25px 15px);

          color: var(--branding3);
          display: table-cell;
          text-align: center;
          width: 18%;

          &:first-child {
            width: 28%;
            text-align: left;
          }

          &.title-cell,
          &.cell {
            border: 1px solid #e6e6e6;
          }
        }

        &:first-child {
          font-weight: 500;
        }

        &.amount {
          @include media-breakpoint-up(lg) {
            @include font-size(24px);

            font-family: var(--walsheim);
            font-weight: 600;
          }
        }

        [data-icon="times"] {
          color: #AAA;
        }
      }

      th.startup {
        position: relative;

        &::after {
          display: block;
          content: '';
          position: absolute;
          left: 15px;
          top: -1px;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMyIgaGVpZ2h0PSIzMyIgdmlld0JveD0iMCAwIDIzIDMzIj4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxwYXRoIGZpbGw9IiMwMDc4RkYiIGQ9Ik04ODEgMTM5MmgxOGwtLjY1OC4zMzNjLTEuNjU4Ljg0LTIuNzEzIDIuNTI5LTIuNzQgNC4zODdsLS40MDMgMjcuMDhjLS4wMDguNTUzLS40NjIuOTk0LTEuMDE0Ljk4Ni0uMjQyLS4wMDQtLjQ3NC0uMDk1LS42NTQtLjI1N2wtNy4yOC02LjU0M2MtLjM4LS4zNDItLjk1Ni0uMzQyLTEuMzM2IDBsLTcuMjQ2IDYuNTE0Yy0uNDExLjM3LTEuMDQzLjMzNi0xLjQxMy0uMDc1LS4xNjUtLjE4NC0uMjU2LS40MjItLjI1Ni0uNjY5VjEzOTdjMC0yLjc2MSAyLjIzOS01IDUtNXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04NzYgLTEzOTIpIi8+ICAgIDwvZz48L3N2Zz4=');
          background-repeat: no-repeat;
          background-size: 100%;
          width: 23px;
          height: 33px;
        }
      }

      th.startup,
      [data-label="Startup"] {
        background-color: #f5faff;
      }
    }

    .get-started-row {
      .bm-btn {
        @include media-breakpoint-up(lg) {
          @include font-size(15px);
          @include padding(10px 0);

          font-weight: 500;
          width: 100%;
        }

        &.startup {
          background-color: #00b0ff;
          border: 1px solid #00b0ff;

          &:hover {
            background-color: darken(#00b0ff, 10%);
            border-color: darken(#00b0ff, 10%);
          }
        }

        &.corporate {
          background-color: #00c7ff;
          border: 1px solid #00c7ff;

          &:hover {
            background-color: darken(#00c7ff, 10%);
            border-color: darken(#00c7ff, 10%);
          }
        }
      }
    }
  }

  .table-title {
    @include font-size(24px);
    @include padding(20px 15px);

    background-color: #F7F7F7;
    color: var(--branding3);
    font-weight: bold;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
  }

  @include media-breakpoint-down(md) {
    .table:before {
      @include font-size(25px);

      content: attr(data-label);
      font-weight: bold;
    }

    .head {
      display: none;
    }

    .trow {
      display: block;
    }

    .cell {
      @include font-size(16px);

      display: block;
      text-align: right;
      clear: both;

      border-left: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;

      &:last-child {
        border-bottom: 1px solid #e6e6e6;
      }
    }

    .table-title {
      @include margin(100px 0 0);

      border-radius: 5px 5px 0 0;
      border-top: 1px solid #e6e6e6;
    }

    .title-cell {
      @include font-size(18px);
      @include padding(15px);

      display: block;
      clear: both;
      text-align: left;
      color: var(--white);
      background-color: var(--branding1);
      font-weight: bold;
      border: 1px solid var(--branding1);
    }

    .get-started-row {
      @include margin(100px 0 0);

      border-top: 1px solid #e6e6e6;

      td {
        &:first-child {
          display: none;
        }
      }
    }

    .cell:before {
      content: attr(data-label);
      float: left;
      text-transform: capitalize;
      font-weight: bold;
    }
  }

  .notice {
    @include font-size(18px);
    @include margin(40px 0 30px 0);

    font-style: italic;
    color: var(--branding3);
  }
}
