//
//  src\pages\Dashboard\DashboardMenu\DashboardMenu.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

.menuTitle {
  @include font-size(12px);
  @include margin(0 0 25px 0);

  color: #cecece;
  font-weight: bold;
  letter-spacing: 2.57px;
  text-transform: uppercase;
}

.dashMenu {
  @include margin(0 0 25px 0);

  list-style: none;
  list-style-type: none;
  list-style-image: none;
  padding: 0;

  li {
    @include margin(0 0 10px 0);

    display: inline-block;
    padding: 0;
    vertical-align: middle;
    width: 100%;

    a {
      @include font-size(15px);
      @include padding(8px 10px);

      align-items: center;
      color: #666;
      display: flex;
      font-weight: 500;
      transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out;
      vertical-align: middle;
      width: 100%;

      svg {
        @include font-size(16px);
        @include margin(0 15px 0 0);

        color: #d7d7d7;
        transition: 0.2s color ease-in-out;
      }

      .transparentBtn {
        background: transparent;
        border: none;
        color: inherit;
        padding: 0;
        &:hover {
          color: var(--white);
        }
      }

      .messageBadge {
        @include font-size(12px);

        margin-left: auto;
      }

      &:hover {
        background-color: #192661;
        color: var(--white);

        svg {
          color: var(--branding2);
        }
      }
    }

    &.selected {
      background-color: #192661;
      color: var(--white);

      a {
        color: var(--white);
      }

      svg {
        color: var(--branding2);
      }
    }
  }
}
