.imagePreview {
  img {
    border: 1px solid #dfdfdf;
    border-radius: 50%;
    height: 150px;
    margin: 0 auto;
    padding: 0;
    width: 150px;
  }
}

.defaultImage {
  max-width: 150px;
}
