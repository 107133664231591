//
//  src\pages\Pricing\components\NamingExamples\NamingExamples.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.neRegion {
  @include margin(50px 0 0);

  @include media-breakpoint-up(md) {
    min-height: 400px;
    padding-top: 50px;
    padding-bottom: 100px;
  }

  @include media-breakpoint-down(md) {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  background-color: #f5faff;

  .neRegionTitle {
    @include font-size(30px);
    @include padding(30px 0);

    color: var(--branding3);
    font-family: var(--walsheim);
    font-weight: bold;
    text-align: center;
    letter-spacing: -.3px;
  }

  .buyRequestExample {
    @include font-size(1.1rem);
    @include padding(25px);

    @include media-breakpoint-down(md) {
      @include margin(0 auto 15px auto);

      max-width: 200px;
      text-align: center;
    }

    text-align: center;
    // text-transform: capitalize;

    a {
      color: var(--branding3) !important;
      font-weight: 800;
    }
  }
}
