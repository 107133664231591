//
//  src\pages\Dashboard\AccountPreferences\UserProfile\ImageUpload\ImageUpload.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../../styles/mixins/rfs';

#imageUpload {
  display: none;
}

.imagePreviewBeforeUpload {
  color: var(--branding3);
  font-weight: bold;
  margin-left: 10px;
}
