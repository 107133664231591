//
//  src\pages\DomainListing\components\SearchByCategory\SearchByCategory.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

.searchCategoryRegion {
  background-color: #f9f9f9;

  .categoryWave {
    margin-bottom: -25px;
  }
}

.sbcRegion {
  @include padding(30px 0);

  background-color: var(--branding3);

  .titleRegion {
    @include padding(0 0 35px);

    text-align: center;
    color: var(--white);

    .title {
      @include font-size(30px);

      font-family: var(--walsheim);
      font-weight: bold;
      letter-spacing: -0.3px;
    }
  }

  .categoryBlockListWrap {
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }

  .categoryBlockList {
    list-style-type: none;
    list-style-image: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0;
    padding: 0;

    .categoryBlock {
      .categoryBlockLink {
        @include padding(12px 25px);
        @include font-size(17px);
        @include margin(0 15px 15px 0);

        min-height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #192661;
        color: var(--white);
        text-align: center;
        font-family: var(--walsheim);
        font-weight: bold;
        // min-height: 64px;
        border-radius: 0.2rem;
        word-wrap: break-word;
        transition: 0.2s background-color ease-in-out;

        &:hover {
          background-color: mix(white, #192661, 5%);
        }
      }

      @include media-breakpoint-only(xl) {
        &:nth-child(5n+5) {
          .categoryBlockLink {
            margin-right: 0;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        width: 20%;
      }

      @include media-breakpoint-only(lg) {
        &:nth-child(3n+3) {
          .categoryBlockLink {
            margin-right: 0;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        width: 33%;
      }

      @include media-breakpoint-only(md) {
        &:nth-child(2n+2) {
          .categoryBlockLink {
            margin-right: 0;
          }
        }
      }

      @include media-breakpoint-down(md) {
        width: 50%;
      }

      @include media-breakpoint-only(sm) {
        &:nth-child(2n+2) {
          .categoryBlockLink {
            margin-right: 0;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        width: 50%;

        &:nth-child(2n+2) {
          .categoryBlockLink {
            margin-right: 0;
          }
        }
      }
    }
  }
}
