//
//  src\general\CustomFile\CustomFile.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.bmFile {
  input {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    + {
      label {
        @include font-size(16px);

        align-items: center;
        background-color: var(--white);
        border: 2px dashed #e6e6e6;
        box-shadow: 0 0 0 4px #fff, 1px 1px 1px 1px rgba(10, 10, 0, 0.5);
        color: var(--branding1);
        cursor: pointer;
        display: flex;
        font-weight: bold;
        height: 50px;
        text-align: center;
        justify-content: center;
        width: 100%;
        transition: .25s border ease-in-out;

        &:hover {
          border-color: var(--branding1);
        }

        svg {
          @include font-size(14px);
          @include margin(0 5px 0);
        }
      }
    }

    &:focus {
      + {
        label {
          background-color: #fefefe;
        }
      }
    }
  }

  .fileSelected {
    color: #40FEC0;
  }
}
