@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

.transactionRow {
  @include padding(10px 15px);
  @include margin(0 0 15px 0);

  background-color: var(--white);
  border: 1px solid #e2e2e2;

  .rowData {
    @include font-size(16px);

    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 60px;
    }

    @include media-breakpoint-down(lg) {
      text-align: center;
    }

    &.domain {
      @include media-breakpoint-up(md) {
        align-items: flex-start;
        flex-direction: column;
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      color: #131313;

      .title {
        @include font-size(18px);

        font-weight: 500;
      }

      .days-ago {
        @include font-size(14px);
      }
    }
  }
}
