//
// src\pages\BuyRequestQuestionnaire\components\Form\Question\Question.module.scss
//

@import '../../../../../styles/mixins/rfs';

.questionAsked {
  @include font-size(24px);
  @include margin(15px 0 0);
  @include padding(10px 0);

  color: var(--branding3);
  font-weight: bold;
  letter-spacing: -.5px;
}

.reactDatepickerWrapper {
  width: 100%;
}
