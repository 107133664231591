//
//  src\pages\Dashboard\Messages\MessageInner\components\MessageOriginal.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../../../styles/mixins/rfs';

.originalMessage {
  @include margin-bottom(50px);

  .messageText {
    border-style: solid;
    border-width: 1px;
    border-color: #dedede;
    padding: 0.9375rem;
  }

  .messageAvatar {
    text-align: right;

    img {
      border-radius: 50%;
      border-color: 1px solid #efefef;
      height: 55px;
      width: 55px;
    }
  }

  .messageAgo {
    @include padding(2px 0);
    @include font-size(12px);

    font-style: italic;
    color: #aaa;
  }

  .messageFrom {
    @include font-size(12px);

    font-weight: bold;
    color: var(--branding1);
  }

  .messageFull {
    @include font-size(18px);

    font-weight: 500;
    color: #131313;
  }
}
