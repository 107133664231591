//
//  src\pages\DomainListing\components\SearchByCategory\NeedHelp\NeedHelp.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../../styles/mixins/rfs';

.needHelp {
  @include margin(50px 0 0 0);

  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 5;
  color: var(--branding3);
  background-color: #f5faff;
  border: 2px solid var(--branding1);
  border-radius: .25rem;
  min-height: 180px;
  margin: 2rem auto 0 auto !important;

  @include media-breakpoint-up(lg) {
    @include padding(15px 30px);
  }

  @include media-breakpoint-up(lg) {
    @include padding(15px);
  }

  .row {
      align-items: center;
      display: flex;
      height: 100%;
      margin: 0;
      width: 100%;
  }

  @include media-breakpoint-down(md) {
    text-align: center;
  }

  .title {
    @include padding(0 30px);

    @include media-breakpoint-up(md) {
      @include font-size(30px);

      line-height: 40px;
    }

    @include media-breakpoint-down(md) {
      @include font-size(25px);

      line-height: 35px;
    }

    font-family: var(--walsheim);
    font-weight: bold;
    letter-spacing: -.3px;
  }

  .subtitle {
    @include padding(0 30px);

    @include media-breakpoint-up(md) {
      @include font-size(30px);
    }

    @include media-breakpoint-down(md) {
      @include font-size(20px);
    }

    line-height: 40px;

    font-family: var(--walsheim);
    letter-spacing: -.3px;
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      @include media-breakpoint-up(lg) {
        min-width: 280px;
        min-height: 50px;
        text-align: center;
      }

      @include media-breakpoint-down(md) {
        @include margin(5px 0);

        min-width: 200px;
        min-height: 40px;
        text-align: center;
      }
    }
  }
}