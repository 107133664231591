@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.verificationPage {
    text-align: center;
    @include padding(120px 0);

    @include margin-bottom(400px);

    @include media-breakpoint-down(md) {
        @include margin-bottom(100px);
    }

    .imgWrapper {
        @include margin-bottom(10px);
    }

    h1 {
        @include font-size(64px);
        font-weight: 900;
        letter-spacing: -0.5px;
        color: var(--branding3);
    }

    p {
        @include font-size(24px);
        margin: 0;
        color: var(--branding3);
    }
}