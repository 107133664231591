.custom-listing {
  .entries {
    position: relative;
    min-height: 100px;

    .no-entries {
      border: 1px solid lightgrey;
      padding: 20px 50px;
      color: #999;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
    }
  }  
}
