//
//  src\pages\Home\Home.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.homeHeader {
  @include media-breakpoint-up(md) {
    min-height: 600px;
  }

  @include margin-top(50px);

  .title {
    @include media-breakpoint-up(lg) {
      @include font-size(64px);

      letter-spacing: -1.83px;
      line-height: 68px;
    }

    @include media-breakpoint-down(lg) {
      @include font-size(54px);

      letter-spacing: -1px;
    }

    @include media-breakpoint-down(md) {
      @include font-size(54px);

      text-align: center;
    }

    color: var(--branding3);
    font-family: var(--walsheim);
    font-weight: bold;

    span {
      color: var(--branding2);
    }
  }

  .description {
    @include font-size(15.5px);
    @include padding(25px 0);

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    color: var(--branding3);
    line-height: 26px;
  }

  .buttonRow {
    @include media-breakpoint-down(md) {
      text-align: center;
      display: flex;
      flex-direction: column;
    }

    button {
      @include media-breakpoint-up(lg) {
        @include margin(0 15px 15px 0);
      }

      @include media-breakpoint-down(md) {
        margin: 0 auto 15px auto;
        max-width: 275px;
        width: 100%;
      }
    }
  }

  .brandmoStats {
    @include padding(30px 0 0);

    font-family: var(--walsheim);

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    .integer {
      @include font-size(42px);

      color: var(--branding2);
      font-weight: bold;
    }

    .label {
      @include media-breakpoint-down(md) {
        @include margin(0 0 15px);
      }

      @include font-size(22px);

      font-weight: 300;
    }
  }

  .error {
    color: red;
    text-align: center;
  }

  .brandmoTopClients {
    @include font-size(14px);
    @include margin(30px 0 0);
    @include padding(15px 0);

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    font-weight: 500;
    border-top: 1px solid #e9e9e9;
    color: #c0c0c0;
  }

  .topClientSVG {
    @include media-breakpoint-down(md) {
      text-align: center;
    }

    .topClientSVGRF {
      @include media-breakpoint-down(md) {
        @include margin(0 0 85px);
      }

      width: 205px;
      max-width: 100%;
      margin: 0 auto;
    }

    .topClientSVGT {
      @include media-breakpoint-down(md) {
        @include margin(0 0 85px);
      }

      width: 95px;
      max-width: 100%;
      margin: 0 auto;
    }

    .topClientSVGKFC {
      width: 45px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.homeTablet {
  position: relative;

  .tabletWrap {
    position: relative;

    .tabletSVG {
      width: 570px;
      height: auto;
      transform: scale(1.07);
    }

    .tabletCard {
      @include media-breakpoint-up(xl) {
        right: 0;
      }

      @include media-breakpoint-down(xl) {
        right: 0;
      }

      position: absolute;
      top: 25%;
      z-index: 1;

      .tabletCardBG {
        .tabletCardSVG {
          max-width: 100%;
          width: 400px;
          height: auto;
          transform-origin: top right;
          -moz-backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          transition: transform 100ms ease-out 0.3s;
        }
      }

      &:hover {
        .tabletCardBG {
          animation: none;

          .tabletCardSVG {
            transform: scale3d(1.435, 1.435, 1.435);
          }
        }
      }
    }
  }
}
