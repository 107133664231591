//
//  src\components\DomainCard\DomainCard.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.domainCard {
  .domainCardWrap {
    @include padding(0);
    @include margin(15px 0);

    @include media-breakpoint-down(md) {
      margin-left: auto;
      margin-right: auto;
      max-width: 320px;
    }

    border: 12px solid #192661;
    background-color: #192661;

    .preview {
      height: 122px;
      background-size: cover;
    }

    .title {
      @include font-size(24px);
      @include margin(10px 0 0 0);

      color: var(--white);
      font-weight: bold;
    }

    .price {
      @include font-size(18px);

      color: var(--branding2);
      font-weight: 500;
    }

    &.listing {
      @include padding(12px);
      @include margin(15px 0);

      @include media-breakpoint-down(md) {
        margin-left: auto;
        margin-right: auto;
        max-width: 320px;
      }

      border: 1px solid #dfdfdf;
      background-color: var(--white);

      .preview {
        height: 122px;
        background-size: cover;
      }

      .title {
        @include font-size(24px);
        @include margin(10px 0 0 0);

        color: var(--branding3);
        font-weight: bold;
      }

      .price {
        @include font-size(18px);

        color: var(--branding2-hov);
        font-weight: 500;
      }
    }

    &.sold {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
