@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.loginFacebook {
  @include font-size(16px);

  align-items: center;
  background-color: #32599d;
  color: #ffffff;
  border: 1px solid #32599d;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  font-family: var(--walsheim);
  font-weight: bold;
  max-width: 100%;
  min-height: 48px;
  width: 100%;

  &:hover,
  &:focus {
    background-color: lighten(#32599d, 5%);
    border: 1px solid #32599d;
  }
}