//
//  src\pages\ForgotPassword\ForgotPassword.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.authBG {
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  height: 100%;
  width: 100%;
  background-color: var(--branding3);
  overflow: auto;
}

.authLogo {
  @include margin(15px);
}

.authWrap {
  margin: 25px auto 25px auto !important;
  max-width: 100%;
  width: 390px;
  text-align: center;
}

.verifyUser {
  .authTitle {
    @include font-size(24px);
    @include margin(15px 0 0);

    font-family: var(--walsheim);
    font-weight: bold;
    letter-spacing: -0.5px;
    color: var(--branding3);
  }

  form {
    @include padding(15px);

    background-color: var(--white);
    border-radius: 0.25rem 0.25rem 0 0;
    min-height: 200px;

    .forgotLabel {
      @include font-size(13px);
      @include padding(15px);
      @include margin(0 15px 15px 15px);

      border-radius: .20rem;
      color: #004085;
      background-color: #cce5ff;
      border: 1px solid #b8daff;
      letter-spacing: .015rem;
      margin-top: 40px;
    }

    .error {
      color: red;
      font-size: 14px;
      text-align: left;
    }
  }
}
