//
//  src\pages\Contest\SubmitEntry\SubmitEntry.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

.submitEntry {
  max-width: 360px;
  margin: 0 auto;
  left: 0;
  right: 0;

  .entryHeader {
    @include padding(5px 15px);

    border: 0;

    .entryHeaderTitle {
      @include font-size(20px);
      @include padding(25px 0 0);

      box-sizing: border-box;
      color: var(--branding3);
      display: block;
      font-family: var(--walsheim);
      font-weight: bold;
      text-align: center;
      max-width: 100%;
      width: 100%;
    }
  }

  .stickyNote {
    @include font-size(12px);
    @include padding(5px 15px);
    @include margin-bottom(15px);

    background-color: #ffffe3;
    border: 1px solid #F0F0F0;
    border-radius: 0.2rem;
    color: #acacac;
  }

  .inputLabel {
    @include font-size(14px);
    @include padding(15px 0 5px 0);

    color: #363636;
  }

  .addStyle {
    > div {
      margin: 0 !important;
    }

    input {
      @include padding(5px 15px);

      border: 1px solid #ced4da;
      border-radius: 0;
      margin: 0;
      min-height: 48px;

      &:focus {
        border-color: var(--branding1);
      }
    }
  }

  .addCheckStyle {
    label {
      @include font-size(14px);

      color: var(--branding3);
    }
  }

  .entryFooter {
    border: 0;

    .entrySubmitButton {
      @include font-size(16px);
      @include padding(15px 0);

      max-width: 100%;
      width: 100%;
      font-family: var(--walsheim);
      font-weight: 600;
    }
  }
}
