//
// src\general\FormInput\FormInput.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.bmInput {
  input {
    @include padding(0 5px);

    color: var(--branding3);
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    min-height: 55px;
    position: relative;

    &::placeholder {
      color: var(--branding3);
    }

    &:active,
    &:focus {
      border-color: var(--branding1);
      box-shadow: none;
      outline: 0;
    }

    &.form-control-sm {
      @include font-size(14px);

      &::placeholder {
        @include font-size(14px);
      }
    }

    &.form-control-lg {
      @include font-size(24px);

      &::placeholder {
        @include font-size(24px);
      }
    }
  }
}
