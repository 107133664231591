//
//  src\pages\Contest\ContestBrief\ContestBrief.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

#contestBrief {
  .brief-description {
    @include padding(25px 0);
    @include font-size(16px);

    color: var(--branding3);
    line-height: 26px;

    p {
      @include padding(0 0 10px 0);
    }
  }

  .brief-info {
    .label {
      @include media-breakpoint-down(md) {
        @include margin(15px 0 0);
      }

      @include font-size(13px);

      color: #777;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      font-family: var(--walsheim);
      font-weight: bold;
    }

    .result {
      @include font-size(20px);
      @include margin(0 0 15px 0);

      font-weight: bold;
      color: var(--branding3);
    }

    .download-file {
      margin-top: 5px;
    }

    .extensions {
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        list-style-image: none;
        list-style-type: none;
      }

      .extension {
        @include font-size(16px);
        @include padding(5px 10px);

        @include media-breakpoint-up(xl) {
          @include margin(5px 10px 0 0);
        }

        background-color: var(--white);
        border: 1px solid #d6d6d6;
        color: #999;
        font-weight: 500;
      }

      .more {
        @include font-size(14px);

        color: var(--branding1);
        font-weight: 500;
      }
    }
  }

  .creator {
    .title {
      @include font-size(24px);
      @include padding(15px 0 0);

      color: var(--branding3);
      font-family: var(--walsheim);
      font-weight: 300;
    }

    .creator-info {
      @include media-breakpoint-down(md) {
        text-align: center;
      }

      @include padding(25px 0);
      @include margin(10px 0);

      border-top: 1px solid #DFDFDF;
      border-bottom: 1px solid #DFDFDF;

      .avatar {
        display: inline-block;

        img {
          border-radius: 100%;
          border: 1px solid var(--branding2);
        }
      }

      .label {
        @include media-breakpoint-down(md) {
          @include margin(15px 0 0);
        }

        @include font-size(13px);

        color: #777;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-family: var(--walsheim);
        font-weight: bold;
      }

      .result {
        @include font-size(20px);

        color: var(--branding3);
        font-weight: bold;
      }
    }
  }
}
