//
// src\general\CustomSelect\CustomSelect.module.scss
//

@import '../../styles/mixins/rfs';

.bmSelect {
  @include margin(0 0 15px);

  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    @include font-size(38px);
    @include padding(0 0 15px);

    align-items: flex-end;
    color: var(--branding1);
    content: '\203A';
    cursor: pointer;
    display: flex;
    height: 55px;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -5px;
    transform: rotate(90deg);
    width: 55px;
  }

  &:active,
  &:focus {
    border-radius: 0;
    outline: none;
    box-shadow: 0;
  }

  &:hover {
    &::after {
      color: var(--branding1-hov);
    }
  }

  select {
    appearance: none;
    background: white;
    background-image: none;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #ced4da;
    border-left: 0;
    border-radius: 0;
    min-height: 55px;
    box-shadow: none;
    color: black;
    cursor: pointer;
    flex: 1;
    outline: 0;
    padding: 0 5px;

    &:active,
    &:focus {
      border-radius: 0;
    }
  }
}
