//
//  src\general\SubmitButton\SubmitButton.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.submitBtnWrap {
  .submitButton {
    @include font-size(16px);

    box-sizing: border-box;
    font-family: var(--walsheim);
    font-weight: bold;
    max-width: 100%;
    min-height: 48px;
    width: 100%;

    &:hover,
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}
