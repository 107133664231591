//
//  src\Pages\DomainListing\DomainInner\components\FAQBlock\FAQBlock.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../../styles/mixins/rfs';

.faqRegion {
  @include padding(50px 0);
  @include margin(50px 0);

  .faqRegionTitle {
    @include font-size(30px);
    @include padding(0 0 25px 0);

    color: var(--branding3);
    font-family: var(--walsheim);
    font-weight: bold;
    text-align: center;
    letter-spacing: -0.3px;
  }
}
