//
//  src\pages\BuyRequests\BuyRequests.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../styles/mixins/rfs";

.requestedItem {
  @include padding(25px 15px);
  @include margin(0 0 15px 0);

  background-color: var(--white);
  border: 1px solid #e2e2e2;
  position: relative;

  .recommended {
    &::after {
      display: block;
      content: '';
      position: absolute;
      left: 28px;
      top: -6px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMyIgaGVpZ2h0PSIzMyIgdmlld0JveD0iMCAwIDIzIDMzIj4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxwYXRoIGZpbGw9IiMwMDc4RkYiIGQ9Ik04ODEgMTM5MmgxOGwtLjY1OC4zMzNjLTEuNjU4Ljg0LTIuNzEzIDIuNTI5LTIuNzQgNC4zODdsLS40MDMgMjcuMDhjLS4wMDguNTUzLS40NjIuOTk0LTEuMDE0Ljk4Ni0uMjQyLS4wMDQtLjQ3NC0uMDk1LS42NTQtLjI1N2wtNy4yOC02LjU0M2MtLjM4LS4zNDItLjk1Ni0uMzQyLTEuMzM2IDBsLTcuMjQ2IDYuNTE0Yy0uNDExLjM3LTEuMDQzLjMzNi0xLjQxMy0uMDc1LS4xNjUtLjE4NC0uMjU2LS40MjItLjI1Ni0uNjY5VjEzOTdjMC0yLjc2MSAyLjIzOS01IDUtNXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04NzYgLTEzOTIpIi8+ICAgIDwvZz48L3N2Zz4=');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 23px;
      height: 33px;
      transform: scale(1, 0.7);
    }
    &::before {
      display: block;
      content: 'Featured';
      position: absolute;
      left: 55px;
      font-weight: 500;
      top: 2px;
      font-size: 12px;
      width: 23px;
      height: 33px;
      color: $primary;
    }
  }
}

.requestedItemTitle {
  @include font-size(24px);

  font-weight: bold;
  color: #192661;
}

.addToFav {
  @include font-size(32px);

  position: absolute;
  right: 10px;

  .favourite {
    color: var(--branding2);
    cursor: pointer;
  }

  svg {
    color: #e2e2e2;
    transition: 0.2s color ease-in-out;

    &:hover {
      color: var(--branding2);
      cursor: pointer;
    }
  }
}

.requestedItemDesc {
  @include font-size(16px);
  @include padding(15px 0);

  color: #3b3b3b;
}

.byUser {
  @include font-size(14px);

  font-weight: 500;

  .byLabel {
    @include margin(0 5px 0 0);

    font-weight: bold;
    color: #131313;
  }

  .byUserName {
    color: var(--branding1);

    a,
    a:visited {
      color: var(--branding1);

      &:hover {
        color: var(--branding1-hov);
      }
    }
  }

  .byPole {
    @include margin(0 5px);

    color: #adadad;
  }
}

.submittedWrap {
  @include font-size(16px);
  @include padding(10px 0);

  .submittedResult {
    color: var(--branding2);
  }

  .submittedLabel {
    @include margin(0 10px 0 0);

    color: #ababab;
  }
}

.keywordListing {
  list-style-image: none;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    @include font-size(16px);
    @include padding(8px 10px);
    @include margin(5px);

    display: inline-block;
    background-color: var(--white);
    border: 1px solid #d6d6d6;
    color: #999;
    font-weight: 500;
    text-transform: capitalize;
    transition: 0.2s background-color ease-in-out, 0.2s color ease-in-out;

    &:hover {
      background-color: darken(white, 5%);
      color: var(--branding3);
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.buyRequestBudgetData {
  @include padding(10px 0);
}

.buyRequestBudget {
  @include font-size(18px);

  @include media-breakpoint-up(md) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  font-weight: 500;

  .budgetLabel {
    color: var(--branding3);
  }

  .budgetAmount {
    color: var(--branding1);
  }

  .viewContest {
    @include padding(10px 8px);

    font-family: var(--walsheim);
    font-weight: 600;
  }
}

.error {
  @include font-size(10px);

  color: red;
  text-align: right;
}
