//
//  src\components\Footer\Footer.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

#footer {
  @include margin(80px auto 0);

  .branding {
    @include media-breakpoint-up(md) {
      @include font-size(28px);
      @include padding(0 0 20px 0);
    }

    @include media-breakpoint-down(md) {
      @include font-size(28px);

      text-align: center;
    }

    font-family: var(--walsheim);
    font-weight: bold;
    color: var(--branding3);
  }

  .copyright {
    @include media-breakpoint-up(md) {
      text-align: left;
    }

    @include media-breakpoint-down(md) {
      @include margin(0 0 20px 0);

      text-align: center;
    }

    color: #949ab7;
  }

  .menuTitle {
    @include font-size(12px);
    @include margin(0 0 20px 0);

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    color: #aaa;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
  }

  .footerMenu {
    @include margin(0 0 25px 0);

    a {
      @include font-size(15px);
      @include padding(0 0 20px 0);

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      color: var(--branding3);

      &:hover,
      &:focus {
        position: relative;
        color: var(--branding1);
      }
    }

    svg {
      @include font-size(22px);
    }
  }

  .socialMenu {
    @include media-breakpoint-up(md) {
      justify-content: flex-start;
    }

    @include media-breakpoint-down(md) {
      justify-content: center;
      .dynamicGap {
        margin-right: 1rem !important; 
      }
    }

    display: flex;
  }
}
