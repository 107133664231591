.buy-requests-list {
  position: relative;
  min-height: 200px;

  .no-buy-requests {
    border: 1px solid lightgrey;
    padding: 20px 50px;
    color: #999;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
  }
}
