//
//  src\pages\Dashboard\AccountPreferences\UserProfile\UserProfile.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

.userProfile {
  @include padding(60px 40px);
  @include margin-bottom(20px);

  border: 1px solid lightgray;
  background-color: var(--white);

  .prefLabel {
    @include font-size(16px);
    @include margin(0 0 10px 0);

    font-weight: bold;
    line-height: 2.13;
    letter-spacing: normal;
    color: var(--branding3);
  }

  .prefDesc {
    @include font-size(16px);
    @include margin(0 0 10px 0);

    font-weight: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: var(--branding3);
  }

  .userProfileForm {
    .profileHeader {
      font-weight: 500;
      color: var(--branding1);
      margin-bottom: 40px;
    }

    .changeUsername {
      @include margin(25px 0);

      .domainAndUsername {
        position: relative;

        .fixedDomain {
          @include font-size(20px);

          color: #cecece;
          position: absolute;
          left: 0;
          top: 12px;
          z-index: 1;
        }

        .addedStyling {
          input {
            padding-left: 135px;
          }
        }
      }
    }

    .error {
      font-size: 14px;
      color: red;
    }

    .apiError {
      padding-top: 10px;
    }

    .updatedSuccessfully {
      font-size: 14px;
      color: green;
      padding-top: 10px;
      font-weight: bold;
    }
  }
}

.user-profile {
  border: 1px solid lightgray;
  padding: 60px 40px;
  margin: 0 auto;
  display: flex;

  .user-profile-form {
    width: 65%;

    .profile-header {
      font-weight: 500;
      color: var(--branding1);
      margin-bottom: 40px;
    }
  }

  .profile-preview {
    width: 30%;
  }

  .upload-image {
    margin-bottom: 4rem;
  }

  .domain-and-username {
    border-bottom: 1px solid darkgray;
    padding: 2px;
    margin-bottom: 1rem;

    input[type="text"] {
      border: none;
    }

    input[type="text"]:focus {
      outline: none;
    }
  }

  .fixed-domain {
    -webkit-appearance: textfield;
    background-color: var(--white);
    padding: 2px 3px;
    margin-top: 5px;
    color: darkgray;
  }

  .save-button {
    padding: 5px 20px;
    background-color: var(--branding1);
    color: white;
    border: none;
    border-radius: 2px;
  }
}
