//
// src\pages\About\About.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

$page-container: 870px;

.aboutPage {
  .headerTitle {
    @include font-size(64px);
    @include padding(70px 0);

    font-family: var(--walsheim);
    font-weight: bold;
    line-height: 0.91;
    letter-spacing: -1.83px;
    text-align: center;
    color: var(--branding3);
    max-width: $page-container;
    margin: 0 auto;
  }

  .headerData {
    margin: 50px auto;
    max-width: $page-container;

    .headerLeft {
      @include font-size(30px);

      font-family: var(--walsheim);
      line-height: 1.2;
      letter-spacing: -0.3px;
      color: var(--branding3);
    }

    .headerRight {
      @include font-size(16px);

      font-family: var(--roboto);
      line-height: 1.63;
      color: var(--branding3);
    }
  }
}
