//
//  src\pages\Pricing\Pricing.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

.pricing-title {
  @include padding(15px);
  @include margin(30px auto 50px auto);

  text-align: center;

  .title {
    @include font-size(64px);

    color: var(--branding3);
    font-family: var(--walsheim);
    font-weight: bold;

    span {
      color: var(--branding2);
    }
  }

  .subtitle {

    @include media-breakpoint-up(lg) {
      @include font-size(17px);
    }

    color: var(--branding3);
    line-height: 1.5;
    max-width: 960px;
    margin: 0 auto;
    text-align: center;
  }
}
