//
// src\general\CustomTextArea\CustomTextArea.module.scss
//

@import '../../styles/mixins/rfs';

.bmTextArea {
  textarea {
    @include font-size(16px);
    @include padding(15px);

    border-radius: 0;
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;

    &::placeholder {
      color: var(--branding3);
    }

    &:active,
    &:focus {
      border-color: var(--branding1);
      box-shadow: none;
      outline: 0;
    }
  }
}
