//
//  src\pages\Dashboard\MyDomains\MyDomains.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

.dashWrapper {
  background-color: #f9f9f9;

  .dashInner {
    @include padding(50px 15px);

    .sectionTitle {
      @include font-size(40px);
      @include margin-bottom(25px);

      font-family: var(--walsheim);
      font-weight: bold;
      line-height: 1.05;
      letter-spacing: -0.67px;
      color: var(--branding3);
    }
  }
}
