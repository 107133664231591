//
//  src\pages\Contest\Contest.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../styles/mixins/rfs';

#contest {
  #header {
    .contest-info {
      position: relative;
      min-height: 200px;

      @include padding(30px 0);

      .loader {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .title {
        @include media-breakpoint-up(lg) {
          @include font-size(64px);

          @include padding(0 0 25px 0);

          line-height: 68px;
          letter-spacing: -1.5px;
        }

        @include media-breakpoint-down(lg) {
          @include font-size(48px);

          @include padding(0 0 25px 0);

          line-height: 48px;
          letter-spacing: -1.5px;
        }

        color: var(--branding3);
        font-family: var(--walsheim);
      }

      .label {
        @include font-size(13px);

        color: #777;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-family: var(--walsheim);
        font-weight: bold;
      }

      .result {
        @include font-size(19px);

        color: var(--branding3);
        font-weight: bold;
      }

      .keywords {
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          list-style-image: none;
          list-style-type: none;
        }

        .keyword {
          @include font-size(16px);
          @include padding(5px 10px);

          @include media-breakpoint-up(xl) {
            @include margin(5px 10px 0 0);
          }

          background-color: var(--white);
          border: 1px solid #d6d6d6;
          color: #999;
          font-weight: 500;
        }

        .more {
          @include font-size(14px);

          color: var(--branding1);
          font-weight: 500;
        }
      }
    }
  }

  #content {
    @include padding(30px 15px 100px);

    background-color: #FAFAFA;

    .nav-tabs {
      .nav-item {
        @include media-breakpoint-up(lg) {
          @include font-size(18px);
          @include padding(10px 10px 15px 10px);
          @include margin(0 15px -1px 0);

          letter-spacing: -.18px;
        }

        @include media-breakpoint-down(lg) {
          @include font-size(16px);
          @include padding(5px 5px 10px 5px);
          @include margin(0 5px -1px 0);

          letter-spacing: -.13px;
        }

        @include media-breakpoint-down(md) {
          @include font-size(14px);
        }

        @include media-breakpoint-down(sm) {
          @include font-size(12px);
        }

        border-bottom: 1px solid transparent;
        background-color: transparent;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        color: #c3c3c3;
        font-family: var(--walsheim);
        font-weight: bold;
        position: relative;
        transition: .2s border ease-in-out, .2s color ease-in-out;

        &:hover,
        &.active {
          color: var(--branding1);
          border-bottom: 1px solid var(--branding1);
        }
      }
    }

    .sidebar {
      @include media-breakpoint-up(lg) {
        @include margin(-265px 0 0);
      }

      .contest-stats {
        background-color: var(--white);
        border: 1px solid #e6e6e6;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .025);
        position: relative;

        .stat-header {
          @include media-breakpoint-up(xl) {
            @include padding(30px);
          }

          @include media-breakpoint-down(xl) {
            @include padding(15px);
          }

          background-color: #F7F7F7;
          color: var(--branding3);

          .prize {
            @include font-size(28px);

            font-weight: bold;
          }

          .days-left {
            @include font-size(16px);
          }

          .creator-action-icon {
            position: absolute;
            right: 25px;
            top: 50px;
            cursor: pointer;
            svg {
              &:hover {
                color: var(--branding1);
              }   
            }
          }
          ul.creator-actions {
            list-style: none;
            padding: 15px;
            position: absolute;
            right: 0px;
            background-color: var(--white);
            @include font-size(12px);
            color: var(--branding3);
            border: 1px solid #e6e6e6;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .025);
            width: 150px;
          }
        }

        .stats {
          @include media-breakpoint-up(xl) {
            @include padding(30px);
          }

          @include media-breakpoint-down(xl) {
            @include padding(15px);
          }

          .label {
            @include font-size(16px);

            color: var(--branding3);
          }

          .result {
            @include font-size(20px);
            @include margin(0 0 15px 0);

            font-weight: bold;
            color: var(--branding3);
          }
        }

        .submit {
          button {
            max-width: 100%;
            width: 100%;
            box-sizing: border-box;
          }
        }

        .contest-progress {
          background-color: #EAEAEA;

          .progress-bar {
            display: block;
            width: 33%;
            height: 5px;
            background-color: var(--branding2);
            position: relative;
          }
        }
      }

      .secure-listing {
        @include font-size(13px);
        @include margin(25px 0);

        color: #afafaf;
        font-weight: bold;
        font-family: var(--walsheim);
        letter-spacing: .02rem;

        svg {
          @include font-size(16px);
        }
      }

      .your-stats {
        @include margin(0 0 25px 0);

        @include media-breakpoint-up(xl) {
          @include padding(30px);
        }

        @include media-breakpoint-down(xl) {
          @include padding(15px);
        }

        background-color: var(--white);
        border: 1px solid #e6e6e6;

        .title {
          @include padding(10px 0 0);

          @include font-size(16px);

          color: #1c1c1c;
          font-family: var(--walsheim);
          font-weight: bold;
        }

        table {
          th {
            @include padding(5px 0);

            color: var(--branding3);
            font-weight: normal;
          }

          td {
            @include padding(5px 0);

            color: var(--branding3);
            font-weight: bold;
          }

          tr,
          td,
          th {
            border: 0;
          }
        }
      }

      .creatives {
        @include padding(30px);
        @include margin(0 0 25px 0);

        background-color: var(--branding3);
        font-family: var(--walsheim);
        text-align: center;
        color: $white;
        font-weight: bold;

        .title {
          @include font-size(48px);

          .favorite {
            color: var(--branding2);
            cursor: pointer;
          }

          svg {
            color: #e2e2e2;
            transition: 0.2s color ease-in-out;
        
            &:hover {
              color: var(--branding2);
              cursor: pointer;
            }
          }
        }

        .subtitle {
          @include font-size(22px);
          @include padding(0 15px);
        }
      }
    }
  }
}
