//
//  src\pages\Contest\QuestionsAnnouncements\QuestionsAnnouncements.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

#questionsAnnouncements {
  .question-post {
    @include margin(25px 0 0);

    .label {
      @include font-size(12px);

      color: #2E2E2E;
      font-weight: bold;
    }

    .question {
      @include font-size(16px);
      @include padding(15px 0);

      color: var(--branding3);
    }
  }

  .poster {
    @include media-breakpoint-up(md) {
      align-items: center;
      display: flex;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    max-width: 100%;
    width: 100%;

    .poster-avatar {
      @include margin(0 15px 0 0);

      width: 42px;
      height: 42px;
      border-radius: 100%;
    }

    .poster-info {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @include media-breakpoint-down(md) {
        text-align: center;
        max-width: 100%;
        width: 100%;
      }

      .poster-name {
        @include font-size(16px);

        color: #2E2E2E;
        font-weight: bold;
      }

      .poster-date {
        @include font-size(16px);

        color: var(--branding3);
      }
    }

    .report {
      @include media-breakpoint-up(md) {
        margin-left: auto;
        align-self: end;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 100%;
      }

      @include font-size(14px);

      font-weight: 300;
      color: #777777;
    }
  }

  .creator-response-post {
    @include padding(15px 20px);
    @include margin(25px 0 0 50px);

    width: 95%;
    background-color: var(--white);
    border: 1px solid #e7e7e7;
    position: relative;

    .label {
      @include font-size(12px);

      color: #2E2E2E;
      font-weight: bold;
      min-height: 20px;

      span {
        font-weight: 300;
      }

      .helpful {
        b {
          font-weight: bold;
        }

        svg {
          color: var(--branding2);
        }
      }
    }

    .response {
      @include font-size(16px);
      @include padding(5px 0);

      line-height: 1.63;

      color: var(--branding3);
      font-weight: bold;
    }
  }

  .announcement-post {
    @include padding(15px 20px);
    @include margin(25px 0 0);

    background-color: var(--white);
    border: 1px solid #e7e7e7;

    .label {
      @include font-size(12px);

      color: #2E2E2E;
      font-weight: bold;
    }

    .announcement {
      @include font-size(16px);
      @include padding(5px 0);

      line-height: 1.63;

      color: var(--branding3);
      font-weight: bold;
    }

    .date {
      @include font-size(14px);

      font-weight: 300;
      color: var(--branding3);
    }
  }

  .question-form {
    @include margin(25px 0 0);

    .user-avatar {
      @include margin(0 15px 0 0);

      float: left;
      width: 48px;
      height: 48px;
      border-radius: 100%;
    }

    button {
      @include margin(15px 0 0);
      @include padding(10px 25px);
    }
  }
}
