//
//  src\pages\BuyRequestQuestionnaire\components\PageCounter\PageCounter.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

#pageCounter {
  @include margin(50px auto);

  max-width: 900px;

  .nav {
    position: relative;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: space-between;

    .nav-item {
      position: relative;

      a {
        @include media-breakpoint-up(lg) {
          @include font-size(30px);

          height: 80px;
          width: 80px;
        }

        @include media-breakpoint-down(md) {
          @include font-size(26px);

          height: 70px;
          width: 70px;
        }

        @include media-breakpoint-down(md) {
          @include font-size(20px);

          height: 40px;
          width: 40px;
        }

        align-items: center;
        background-color: var(--white);
        border-radius: 100%;
        border: 2px solid #e6e6e6;
        color: #AAA;
        display: flex;
        font-family: var(--walsheim);
        font-weight: bold;
        justify-content: center;
        margin: 0;
        padding: 0;
        position: relative;
        transition: .2s color ease-in-out, .2s background-color ease-in-out, .2s border-color ease-in-out;
        z-index: 1;

        &.active {
          background-color: var(--branding1);
          color: var(--white);
          border-color: var(--branding1);
        }
      }
    }

    &::after {
      display: block;
      background-color: #e6e6e6;
      content: '';
      left: 0;
      height: 3px;
      position: absolute;
      top: calc(50% - 1.5px);
      width: 100%;
      z-index: 0;
    }
  }
}
