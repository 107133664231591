//
//  src\pages\Home\components\Testimonials\Testimonials.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

#testimonials {
  @include padding(100px 0);

  position: relative;
  text-align: center;

  .titleRegion {
    @include padding(0 0 35px);

    text-align: center;
    color: var(--branding3);

    .title {
      @include font-size(30px);

      font-family: var(--walsheim);
      font-weight: bold;
      letter-spacing: -0.3px;
    }
  }

  .testimonials {
    font-size: xx-large;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .custom-carousel-indicators {
    margin: 0 auto;
    width: 360px;
    display: flex;
    list-style: none;
    padding-left: 0px;
    align-items: center;
  }

  .custom-carousel-indicators li {
    width: 120px;
    height: auto;
    max-height: 100px;
    background-color: transparent;
  }

  .custom-carousel-indicators li.active {
    width: 120px;
    height: auto;
    max-height: 100px;
    background-color: transparent;
  }

  .custom-carousel-indicators li > .thumbnail {
    width: 120px;
    height: auto;
    overflow: hidden;
    display: block;
    padding: 0;
    margin-bottom: 0;
    background-color: white;
    border: none;
  }

  .custom-carousel-indicators li:hover > .thumbnail,
  .custom-carousel-indicators li.active > .thumbnail:hover {
    position: relative;
    display: block;
    opacity: 1;
    z-index: 99;
  }

  .custom-carousel-indicators li > .thumbnail > .person-image {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    opacity: 0.5;
  }

  .custom-carousel-indicators li.active > .thumbnail > .person-image {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    opacity: 1;
    transition: all 300ms ease-out;
  }

  .custom-carousel-indicators li > .thumbnail > .person-image:hover {
    cursor: pointer;
  }

  .custom-carousel-indicators li > .thumbnail > .person-name {
    font-size: 12px;
    font-weight: 600;
  }

  .custom-carousel-indicators li > .thumbnail > .person-profession {
    font-size: 14px;
  }
}
