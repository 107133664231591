//
//  src\pages\Contest\ViewEntries\ContestEntry\ContestEntry.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

.contest-entry {
  @include padding(10px 15px);
  @include margin(0 0 15px 0);

  background-color: var(--white);
  border: 1px solid #e2e2e2;

  .entry-data {
    @include font-size(16px);

    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 60px;
    }

    @include media-breakpoint-down(lg) {
      text-align: center;
    }

    &.description {
      @include media-breakpoint-up(md) {
        align-items: flex-start;
        flex-direction: column;
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      color: #131313;

      .title {
        @include font-size(18px);

        font-weight: 500;
      }

      .days-ago {
        @include font-size(14px);
      }
    }

    &.rating {
      position: relative;

      .rated {
        color: var(--branding1);
      }

      .unrated {
        color: #CCCCCC;
      }

      .rating-allowed {
        cursor: pointer;
      }

      #loader {
        position: absolute;
        left: 35%;
        transform: translateX(10px);
      }
    }

    &.amount {
      .amount-integer {
        color: #131313;
        font-weight: bold;
      }
    }

    &.favorite {
      @include font-size(25px);

      position: relative;

      .disabled-fav {
        pointer-events: none;
      }

      .heart-fav {
        color: var(--branding2);
        cursor: pointer;
      }

      svg {
        color: #e2e2e2;
        transition: 0.2s color ease-in-out;

        &:hover {
          color: var(--branding2);
          cursor: pointer;
        }
      }
    }

    &.buy-now {
      .buy-now-label {
        color: var(--branding1);
        font-weight: bold;
        text-decoration: underline;
        transition: .2s opacity ease-in-out;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        &:hover {
          outline: none;
        }
        &:focus {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }

      .purchased {
        color: green;
        font-weight: bold;
      }
    }

    &.comments {
      color: #131313;

      .entry-comments {
        color: var(--branding1);
      }
    }

    &.open-entry {
      .entry-chevron {
        @include font-size(20px);

        color: var(--branding1);
      }
    }
  }

  .user-interact {
    @include media-breakpoint-up(md) {
      display: flex;

      .block-user {
        @include margin(0 0 0 auto);

        justify-content: flex-end;
      }
    }

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;

      button {
        @include margin(5px 0);
      }
    }

    button {
      @include padding(5px 25px);
    }

    .add-shortlist {
      @include media-breakpoint-up(lg) {
        @include margin(0 10px 0 0);
      }
    }
  }

  .user-send-msg {
    display: flex;
    width: 100%;
    max-width: 100%;
    align-items: center;
    margin-bottom: 20px;
    .sender-block {
      display: flex;
      max-width: 63px;
      margin-right: 10px;
      flex-direction: column;
      .message-sender {
        font-weight: bold;
        margin-top: 5px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .message-time {
        font-size: 12px;
      }
    }
    .avatar {
      @include margin(0 15px 0 0);
      height: 48px;
      border-radius: 100%;
    }

    .user-msg-field {
      height: 48px;
    }

    button {
      height: 48px;
    }
  }
  
  .offer-accepted {
    color: green;
    font-weight: bold;
  }

  .counter-offer-block {
    margin-top: 1rem !important;
    align-self: flex-end; 
  }

  .delete-btn {
    color: #afafaf;
    display: inline-block;
    padding-bottom: 5px;
    cursor: pointer;
    &:hover {
      color: var(--branding1);
    }
  }

  .delete-bottom-row {
    border-bottom: 1px solid #dee2e6;
  }
}

.error {
  font-size: 14px;
  color: red;
  display: block;
}

.loader-wrap {
  min-height: 50px;
}
