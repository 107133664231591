//
//  src\pages\Dashboard\Messages\Messages.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

.dashWrapper {
  background-color: #f9f9f9;

  .dashInner {
    @include padding(50px 15px);

    .sectionTitle {
      @include font-size(40px);
      @include margin-bottom(25px);

      font-family: var(--walsheim);
      font-weight: bold;
      line-height: 1.05;
      letter-spacing: -0.67px;
      color: var(--branding3);
    }
  }
}

.messagesContainer {
  background-color: var(--white);
  border: 1px solid #e2e2e2;

  .messagesFilters {
    @include padding(20px 10px);

    .textFilter {
      @include font-size(16px);

      @include media-breakpoint-up(lg) {
        text-align: left;
      }

      @include media-breakpoint-down(md) {
        @include margin-bottom(15px);
      }

      .textFilterList {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          @include padding-right(10px);
          @include margin-right(10px);

          display: inline-block;
          font-weight: 500;
          border-right: 1px solid #cecece;

          &.filterUnread {
            div {
              color: #b3b3b3;
              cursor: pointer;

              span {
                &.notZero {
                  color: var(--branding1);
                }
              }

              &:hover {
                color: var(--branding1);
              }
            }
          }

          &:last-child {
            border: none;
          }

          &.filterAll {
            div {
              color: var(--branding1);
              cursor: pointer;

              &:hover {
                color: var(--branding1);
              }
            }
          }
        }
      }
    }

    .searchFilter {
      @include media-breakpoint-up(lg) {
        text-align: right;
      }

      position: relative;

      .searchButton {
        position: absolute;
        right: 12px;
        top: 12px;
        color: var(--branding3);

        &:hover {
          color: var(--branding3-hov);
        }
      }

      &.addStyling {
        > div {
          margin: 0 !important;
        }

        input {
          @include padding(5px 15px);

          border: 1px solid #ced4da;
          border-radius: 0;
          margin: 0;
          min-height: 48px;

          &:focus {
            border-color: var(--branding1);
          }
        }
      }
    }
  }

  .noMessages {
    border: 1px solid lightgrey;
    padding: 20px 50px;
    color: #999;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
  }

  .messagesWrapper {
    @include padding(25px);

    position: relative;
  }
}
