@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

#my-contests {
  @include padding(30px 15px 30px);

  background-color: #FAFAFA;

  .section-title {
    @include font-size(40px);
    @include margin-bottom(25px);

    font-family: var(--walsheim);
    font-weight: bold;
    line-height: 1.05;
    letter-spacing: -0.67px;
    color: var(--branding3);
  }

  .nav-tabs {
    .nav-item {
      @include media-breakpoint-up(lg) {
        @include font-size(18px);
        @include padding(10px 10px 15px 10px);
        @include margin(0 15px -1px 0);

        letter-spacing: -.18px;
      }

      @include media-breakpoint-down(lg) {
        @include font-size(16px);
        @include padding(5px 5px 10px 5px);
        @include margin(0 5px -1px 0);

        letter-spacing: -.13px;
      }

      @include media-breakpoint-down(md) {
        @include font-size(14px);
      }

      @include media-breakpoint-down(sm) {
        @include font-size(12px);
      }

      border-bottom: 1px solid transparent;
      background-color: transparent;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      color: #c3c3c3;
      font-family: var(--walsheim);
      font-weight: bold;
      position: relative;
      transition: .2s border ease-in-out, .2s color ease-in-out;

      &:hover,
      &.active {
        color: var(--branding1);
        border-bottom: 1px solid var(--branding1);
      }
    }
  }
}
