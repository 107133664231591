@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../styles/mixins/rfs';

.searchList {
  position: relative;

  .entrySearchSubmit {
    position: absolute;
    top: 2px;
    right: 15px;
    background-color: transparent;
    border: transparent;
    color: #1C1C1C;
  }

  .export {
    width: 100%;
    max-width: 100%;
  }

  .resetFilters {
    width: 100%;
    font-size: 1rem;
    padding: 0.3125rem 0.3125rem;
    background-color: var(--white);
    border: 1px solid #d6d6d6;
    border-radius: 0;
    color: #999;
    font-weight: 500;

    &:hover {
      background-color: var(--white);
      border: 1px solid var(--branding1);
      color: var(--branding1);
    }

    &:active {
      background-color: var(--white) !important;
      border: 1px solid var(--branding1) !important;
      color: var(--branding1) !important;
      outline: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
