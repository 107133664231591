//
//  src\pages\DomainListing\DomainInner\components\DesignConcept\DesignConcept.module.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../../styles/mixins/rfs';

.designConcept {
  @include margin(0 auto 50px auto);

  .conceptTitle {
    @include font-size(30px);
    @include padding(25px 0 50px 0);

    font-family: var(--walsheim);
    color: var(--branding3-hov);
    font-weight: bold;
    letter-spacing: -0.3px;
    text-align: center;
  }

  .conceptBlock {
    @include media-breakpoint-down(md) {
      max-width: 400px;
      margin: 0 auto;
    }
  }

  .conceptImage {
    @include margin(0 0 50px 0);

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .conceptData {
    .conceptDataTitle {
      @include font-size(18px);
      @include margin(10px 0 0 0);

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      color: var(--branding3-hov);
      font-weight: bold;
    }

    .conceptDataDescription {
      @include font-size(16px);
      @include padding(10px 0);

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-down(md) {
        @include font-size(15px);
        @include margin-bottom(75px);

        text-align: center;
      }

      color: var(--branding3-hov);
      line-height: 1.63;
    }
  }
}
