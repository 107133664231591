//
//  src\pages\BuyRequestQuestionnaire\components\Form\Form.scss
//

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../../../styles/mixins/rfs';

#questions {
  .error {
    color: red;
    font-size: 14px;
  }

  .api-error {
    padding-top: 10px;
  }

  @include padding(50px);

  @include media-breakpoint-up(md) {
    @include margin(0 auto 75px auto);
  }

  @include media-breakpoint-down(md) {
    @include margin(0 auto 50px auto);
  }

  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .02), 0 9px 10px 1px rgba(0, 0, 0, .04), 0 4px 10px 3px rgba(0, 0, 0, .05);

  .questionAsked { // @TODO: Remove when ALL questionnaire components have been implemented properly.
    @include font-size(24px);
    @include margin(15px 0 0);
    @include padding(10px 0);

    color: var(--branding3);
    font-weight: bold;
    letter-spacing: -.5px;
  }

  .compare-plans {
    @include font-size(14px);
    @include padding(5px 0);

    color: var(--branding1);
  }

  .select-plan {
    .item {
      @include media-breakpoint-down(lg) {
        @include margin(0 0 25px 0);
      }

      align-items: center;
      border: 2px solid #e6e6e6;
      border-radius: .25rem;
      color: var(--branding3);
      display: flex;
      position: relative;
      flex-direction: column;
      font-family: var(--walsheim);
      justify-content: center;
      min-height: 125px;
      transition: .25s background-color ease-in-out, .25s border ease-in-out;

      .title {
        @include font-size(20px);

        font-weight: bold;
      }

      .price {
        @include padding(5px 0 0);
        @include font-size(24px);
      }

      &:hover {
        background-color: #f5faff;
        border-color: #0078ff;
        cursor: pointer;
      }

      &.recommended {
        &::after {
          display: block;
          content: '';
          position: absolute;
          right: 0;
          top: -1px;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMyIgaGVpZ2h0PSIzMyIgdmlld0JveD0iMCAwIDIzIDMzIj4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxwYXRoIGZpbGw9IiMwMDc4RkYiIGQ9Ik04ODEgMTM5MmgxOGwtLjY1OC4zMzNjLTEuNjU4Ljg0LTIuNzEzIDIuNTI5LTIuNzQgNC4zODdsLS40MDMgMjcuMDhjLS4wMDguNTUzLS40NjIuOTk0LTEuMDE0Ljk4Ni0uMjQyLS4wMDQtLjQ3NC0uMDk1LS42NTQtLjI1N2wtNy4yOC02LjU0M2MtLjM4LS4zNDItLjk1Ni0uMzQyLTEuMzM2IDBsLTcuMjQ2IDYuNTE0Yy0uNDExLjM3LTEuMDQzLjMzNi0xLjQxMy0uMDc1LS4xNjUtLjE4NC0uMjU2LS40MjItLjI1Ni0uNjY5VjEzOTdjMC0yLjc2MSAyLjIzOS01IDUtNXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04NzYgLTEzOTIpIi8+ICAgIDwvZz48L3N2Zz4=');
          background-repeat: no-repeat;
          background-size: 100%;
          width: 23px;
          height: 33px;
        }
      }

      &.selected-plan {
        background-color: #f5faff;
        border-color: #0078ff;
        cursor: pointer;
      }
    }
  }

  .payment-method {
    @include media-breakpoint-up(lg) {
      display: flex;
    }

    .item {
      @include padding(15px);

      @include media-breakpoint-up(lg) {
        @include margin(0 30px 0 0);
      }

      @include media-breakpoint-down(lg) {
        @include margin(0 auto 25px auto);
      }

      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 150px;
      max-width: 230px;
      border: 2px solid #e6e6e6;
      border-radius: .25rem;
      color: var(--branding3);
      position: relative;
      transition: .25s background-color ease-in-out, .25s border ease-in-out;
      width: 100%;

      .logo {
        position: relative;
      }

      .title {
        @include font-size(18px);
        @include margin(15px 0 0 0);

        color: var(--branding3);
        position: relative;
      }

      &.active,
      &:hover {
        background-color: #f5faff;
        border-color: #0078ff;
        cursor: pointer;
      }

      &::before {
        @include font-size(22px);

        align-items: center;
        content: '\2714';
        color: #0078ff;
        display: flex;
        justify-content: center;
        position: absolute;
        right: -20px;
        top: -20px;
        background-color: #f5faff;
        border: 1px solid #0078ff;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        transform: scale(0);
        transition: .25s transform ease-in-out;
      }

      &.active {
        &::before {
          transform: scale(1);
        }
      }
    }
  }

  .step-name {
    @include font-size(16px);

    color: var(--branding1);
    font-family: var(--walsheim);
    font-weight: bold;
    letter-spacing: 1.33px;
    text-transform: uppercase;
  }

  .step-controller {
    @include margin(50px 0 0);

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    button {
      @include font-size(16px);
      @include margin(10px 5px);

      min-width: 155px;

      &.go-back {
        background-color: #f1f7fe;
        border: 1px solid #f1f7fe;
        color: var(--branding3);

        &:hover {
          background-color: var(--branding3);
          border: 1px solid var(--branding3);
          color: var(--white);
        }
      }
    }
  }
}
