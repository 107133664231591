// Fonts
@import './typography/fonts';

// Responsive Font Size, Margin & Padding mixins
@import './mixins/rfs';

// SCSS to CSS Variables
@import './mixins/css-vars';

// Bootstrap
@import './base/bootstrap';

// Base
@import './base/vars';
@import './base/global';

// Utilities
@import './utilities/opacity';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// Components
@import './components/btn';
@import './components/anchor';
